import { format } from 'date-fns';
import { Fragment, HTMLInputTypeAttribute, SyntheticEvent, useState } from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import OtpInput from 'react-otp-input';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import { SessionValidityChecker } from '../components/SessionValidityChecker';
import Alert from '../components/alert';
import CopyToClipboardInput from '../components/clipboard';
import CopyButton from '../components/copyBtn';
import EditableText from '../components/editableText';
import LoaderContainer from '../components/loader';
import Modal from '../components/modal';
import Table from '../components/table';
import { configs } from '../config';
import useAlert from '../hooks/useAlert';
import useModal from '../hooks/useModal';
import { trpc } from '../lib/trpc';
import AddBankDEstinationForm from '../section/add-bank-trf';
import UserDelettingUnblockBankDefault from '../section/delettingBank';
import KycEvaluation from '../section/kycEvaluation';
import BankDownloadButton from '../section/pdfDocuments/bankDownloadButton';
import WalletDownloadButton, { GroupedWalletData } from '../section/pdfDocuments/walletDownloadButton';
import UserSetUnblockBankDefault from '../section/settingBankDefault';
import UploadKycDoc from '../section/upload-kyc-document';
import UserBridgeSection from '../section/userBridge';
import { isEvmAddress } from '../utils/isEvmAddress';
import { isSolanaAddress } from '../utils/isSolanaAddress';

interface Option {
    value: string;
    label: string;
}

type Column = {
    label: string;
    key: string;
    format?: (v1: any, v2?: any) => any;
    meta?: { type: HTMLInputTypeAttribute | 'select'; options?: Option[] } | null;
};

type FeeOpt = 'add' | 'subtract' | '';
type Currency = 'EUR' | 'GBP' | 'USD' | '';
type Direction = 'OFFRAMP' | 'ONRAMP' | '';
export type UnblockBankObject = {
    currency: string;
    account_number: string;
    sort_code: string;
    iban: string;
    uuid: string;
    bank_country: string;
    bic: string;
};

type TransactionData = {
    status: 'PROCESS_COMPLETED' | 'OUTSIDE_TRANSFER_REJECTED';
    user_uuid: string;
    direction: 'cryptoToFiat' | 'fiatToCrypto';
    input: {
        amount: number;
        currency: string;
        transaction_id: string;
    };
    output: {
        amount?: number; // Optional for "OUTSIDE_TRANSFER_REJECTED"
        currency: string;
        transaction_id?: string; // Optional for "OUTSIDE_TRANSFER_REJECTED"
    };
    created_at: string;
};

const transactionColumn: Column[] = [
    { label: 'Created At', key: 'created_at', format: (date: string) => format(date, 'hh:mm dd MMMM, yyyy') },
    { label: 'Status', key: 'status', format: (status: string) => status?.replaceAll('_', ' ') },
    { label: 'Direction', key: 'direction' },
    { label: 'Input Amount', key: 'input.amount' },
    { label: 'Input Currency', key: 'input.currency' },
    { label: 'Output Amount', key: 'output.amount' },
    { label: 'Output Currency', key: 'output.currency' },
    { label: 'Input Transaction ID', key: 'input.transaction_id' },
    { label: 'Output Transaction ID', key: 'output.transaction_id' },
    { label: 'User UUID', key: 'user_uuid' },
];

const unblockWalletColumns: Column[] = [
    { label: 'Network', key: 'network' },
    {
        label: 'Address',
        key: 'address',
    },
];

const unblockBankAccountsColumns: Column[] = [
    { label: 'Account Name', key: 'account_name' },
    { label: 'Account Number', key: 'account_number' },
    { label: 'Bank Code', key: 'bank_code' },
    { label: 'Bank Country', key: 'bank_country' },
    { label: 'BIC', key: 'bic' },
    { label: 'Currency', key: 'currency' },
    { label: 'IBAN', key: 'iban' },
    { label: 'Sort Code', key: 'sort_code' },
];
const tokenPreferencesColumn: Column[] = [
    {
        label: 'Currency Name',
        key: 'currency',
        meta: null,
    },
    {
        label: 'Chain',
        key: 'chain',
        meta: {
            type: 'select',
            options: [
                { value: 'polygon', label: 'polygon' },
                { value: 'celo', label: 'celo' },
                { value: 'arbitrum', label: 'arbitrum' },
                { value: 'optimism', label: 'optimism' },
                { value: 'tron', label: 'tron' },
                { value: 'mainnet', label: 'mainnet' },
                { value: 'solana', label: 'solana' },
                { value: 'base', label: 'base' },
            ],
        },
    },
    {
        label: 'Token',
        key: 'token',
        meta: {
            type: 'select',
            options: [
                { value: 'usdc', label: 'usdc' },
                { value: 'usdce', label: 'usdce' },
                { value: 'usdt', label: 'usdt' },
                { value: 'cEUR', label: 'cEUR' },
                { value: 'cUSD', label: 'cUSD' },
                { value: 'agEUR', label: 'agEUR' },
            ],
        },
    },
];
const bankDestinationAccounts: Column[] = [
    { label: 'id', key: 'uuid' },
    { label: 'Account Number', key: 'account_number' },
    { label: 'Account Name', key: 'account_name' },
    { label: 'Main Beneficiary', key: 'main_beneficiary' },
    { label: 'IBAN', key: 'iban' },
    { label: 'Sort Code', key: 'sort_code' },
    { label: 'Currency', key: 'currency' },
];

const bankDestinationAccts: Column[] = [
    { label: 'Account Number', key: 'accountNumber' },
    { label: 'Account Name', key: 'accountName' },
    { label: 'Main Beneficiary', key: 'useAsDefault' },
    { label: 'IBAN', key: 'iban' },
    { label: 'Sort Code', key: 'sortCode' },
    { label: 'Currency', key: 'currency' },
];

const customFeeConfig = [
    {
        label: 'Input Currency',
        key: 'input_currency',
    },
    {
        label: 'Output Currency',
        key: 'output_currency',
    },
    {
        label: 'Direction',
        key: 'direction',
    },
    {
        label: 'Percentage Fee',
        key: 'percentage_fee',
    },
    {
        label: 'Fee Op Type',
        key: 'fee_op_type',
    },
];

const walletAddressSchema = z.object({
    walletAddress: z.string().refine(value => isEvmAddress(value), {
        message: 'Invalid Ethereum address',
    }),
});

const targetSolanaAddressSchema = z.object({
    targetSolanaAddress: z.preprocess(
        value => {
            if ((typeof value === 'string' && value.trim() === '') || value === undefined || value == null) {
                return undefined;
            }
            return value;
        },
        z
            .string()
            .optional()
            .refine(value => value === undefined || isSolanaAddress(value), {
                message: 'Invalid Solana address',
            }),
    ),
});

const activityFilter = [
    {
        label: 'Unblock Data',
        value: 'unblock_data',
    },
    {
        label: 'Bridge Data',
        value: 'bridge_data',
    },
];

function UserView() {
    const [otp, setOtp] = useState('');
    const [fee, setFee] = useState('');
    const [inputCurrency, setInputCurrency] = useState<Currency>('');
    const [outputCurrency, setOutputCurrency] = useState<Currency>('');
    const [direction, setDirection] = useState<Direction>('');
    const [feeOpType, setFeeOpType] = useState<FeeOpt>('');
    const [currency, setCurrency] = useState<'GBP' | 'EUR'>('EUR');
    const { alert, showAlert } = useAlert();
    const { isModalVisible, setModalClose, setModalOpen } = useModal();
    const [holdUnblockBankData, setHoldUnblockBankData] = useState<UnblockBankObject | null>(null);
    const [tabValue, setTabValue] = useState('unblock_data');

    const params = useParams<{ id: string }>() as { id: string };
    //
    if (!params?.id && params.id === undefined) {
        return null;
    }
    const {
        data: userData,
        isLoading: userIsLoading,
        isError: userIsError,
        refetch: refetchUserData,
    } = trpc.admin.getById.useQuery(params?.id);
    const { data: companyData, isLoading: corporateDataIsLoading } = trpc.corporate.getByIdAdminId.useQuery(
        +params?.id,
    );
    const { data: unblockIndividualData, isLoading: isLoadingUnblockIndividualData } =
        trpc.adminUnblock.getUnblockIndividualData.useQuery(+params?.id);

    const { data: unblockWallets, isLoading: unblockWalletsLoading } = trpc.admin.listUnblockWallets.useQuery(
        +params?.id,
    );

    const {
        data: unblockDestBankAcct,
        isLoading: uIsLoadingUnblockDesBankAcct,
        isRefetching: isRefetchingUnblockDesBankAcct,
    } = trpc.admin.listUnblockDestinationBankAccounts.useQuery(+params?.id);

    const { data: userLastSession, refetch: refetchUserLastSession } = trpc.admin.getUserLastSession.useQuery(
        +params?.id,
    );

    const { data: kycApplication } = trpc.admin.getKycApplication.useQuery(+params?.id);

    const { data: listUserDestinationBankAccounts, isLoading: isLoadingListUserDestinationBankAccounts } =
        trpc.admin.listUserDestinationBankAccounts.useQuery(+params?.id);

    const {
        data: tokenPreferences,
        refetch: RefetchtokenPreferences,
        isLoading: isLoadingTokenPreferences,
    } = trpc.admin.getTokenPreferences.useQuery(+params?.id);
    const {
        data: unblockBankAccounts,
        isLoading: unblockBankAccountsIsLoading,
        refetch: refetchUnblockBankAccounts,
    } = trpc.admin.listUnblockBankAccounts.useQuery(Number(params.id));
    const { mutate: createBankMutation, isLoading: isLoadingCreatingBankAcct } =
        trpc.admin.createUnblockBankAccount.useMutation({
            onSuccess: () => {
                showAlert('Unblock bank account sucessfully created!', 'success', 5000);
                refetchUnblockBankAccounts();
            },
            onError: (error: any) => {
                console.error('response=====>', error);
            },
        });

    const { mutate: onTriggerOtp, isLoading: IsOtpprocessing } = trpc.admin.triggerOtp.useMutation({
        onSuccess: () => {
            showAlert('OTP sent successfully', 'success');
        },
        onError: error => {
            showAlert(error.message, 'error');
        },
    });

    const { mutate: onExchangeOtpForSessionId } = trpc.admin.exchangeOtpForSessionId.useMutation({
        onSuccess: () => {
            refetchUserLastSession();
            RefetchtokenPreferences();
            setOtp('');
        },
        onError: e => {
            showAlert(e?.message, 'error');
        },
    });

    const { mutate: setUnblockTokenPreferences } = trpc.admin.setUnblockTokenPreferences.useMutation({
        onError: (e: any) => {
            showAlert(e?.message, 'error');
        },
        onSuccess: () => {
            showAlert('Preferences successfully Updated', 'success');
        },
    });

    const { mutate: createUnblockKycApplication, isLoading: isCreateUnblockKycApp } =
        trpc.admin.createUnblockKycApplication.useMutation({
            onError: error => {
                showAlert(error.message, 'error');
            },
            onSuccess: () => {
                showAlert('Application successfully created', 'success');
            },
        });

    const { mutate: updateUserMutation } = trpc.admin.updateUser.useMutation({
        onError: error => {
            console.log('updateUserMutation', error.message);
            showAlert(error.message, 'error');
            refetchUserData();
        },
        onSuccess: () => {
            showAlert('User successfully updated', 'success');
            refetchUserData();
        },
    });

    const {
        data: kycUrl,
        isLoading: isLoadingkycUrl,
        isFetching: isFetchingkycUrl,
    } = trpc.admin.getKycUrl.useQuery(+params?.id);

    const {
        data: transactionData,
        isLoading: transactionIsLoading,
        isError: transactionIsError,
    } = trpc.admin.listUnblockTransactions.useQuery<TransactionData[]>(Number(params.id));

    if (userIsError && transactionIsError) {
        return <p>An Error occurred</p>;
    }

    if (!userIsLoading && !userData) {
        return <p>User not found</p>;
    }

    const triggerOTP = () => {
        if (params?.id) onTriggerOtp(+params.id);
    };
    const handleChange = (otp: string) => {
        setOtp(otp);
        // Check if the OTP otp length is 6
        if (otp.length === 6 && params?.id) {
            // Trigger API call to send OTP
            onExchangeOtpForSessionId({ id: +params.id, otp });
        }
    };

    const handleChangeCurrency = (event: SyntheticEvent) => {
        const target = event.target as typeof event.target & { value: 'GBP' | 'EUR' };
        setCurrency(target.value);
    };

    const handleSubmit = () => {
        params.id && createBankMutation({ id: +params.id, currency });
    };

    const updateUser = (type: string) => {
        return function (value: string) {
            console.log({ type, value, id: params.id });
            params?.id && updateUserMutation({ id: +params.id, [type]: value });
        };
    };
    const onClickSaveTokenPreferences = (row: any) => {
        params?.id && setUnblockTokenPreferences({ id: +params?.id, ...row });
    };

    const onCreateUnblockKycApplication = (e: SyntheticEvent) => {
        e.preventDefault();
        params?.id && createUnblockKycApplication(+params.id);
    };

    const { mutate, isLoading } = trpc.admin.updateMerchantCustomerFee.useMutation({
        onSuccess: () => {
            showAlert('Individual fee successfully updated', 'success');
            setInputCurrency('');
            setOutputCurrency('');
            setDirection('');
            setFeeOpType('');
            setFee('');
        },
        onError: () => {
            showAlert('Individual fee failed to update', 'error');
        },
    });

    const handleConfigSubmit = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        if (!params?.id || !inputCurrency || !outputCurrency || !direction || !feeOpType) return;
        const feeData = {
            userId: +params?.id,
            fee: parseFloat(fee),
            input_currency: inputCurrency,
            output_currency: outputCurrency,
            direction,
            fee_op_type: feeOpType,
        };
        mutate(feeData);
    };

    const textToCopyData = [
        {
            walletAddress: userData ? userData?.walletAddress : null,
            solanaWalletAddress: userData ? userData?.targetSolanaAddress : null,
            bankDestinationAccts:
                userData && !isLoadingListUserDestinationBankAccounts ? listUserDestinationBankAccounts : null,
            unblockWallets: userData && !unblockWalletsLoading ? unblockWallets : null,
            unblockBankAccts: userData && !unblockBankAccountsIsLoading ? unblockBankAccounts : null,
            unblockDestBankAcct: userData && !uIsLoadingUnblockDesBankAcct ? unblockDestBankAcct : null,
        },
    ];

    const isEmpty = (data: any) => Array.isArray(data) && data.length === 0;

    const sortTransactionData = transactionData?.sort(
        (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    );

    return (
        <div>
            {alert && <Alert classes={'w-[20%] ml-auto'} message={alert.message} event={alert.type} />}
            <Modal
                title="Add Destination Bank Account"
                showModal={isModalVisible(3)}
                closeModal={() => setModalClose(3)}
            >
                <AddBankDEstinationForm setModalClose={() => setModalClose(3)} setModalOpen={() => setModalOpen(3)} />
            </Modal>

            <Modal title="Evaluate Kyc Application" showModal={isModalVisible(4)} closeModal={() => setModalClose(4)}>
                <KycEvaluation setModalClose={() => setModalClose(4)} id={+params.id} />
            </Modal>
            <Modal title="Unblock Bank Account" showModal={isModalVisible(5)} closeModal={() => setModalClose(5)}>
                <UserSetUnblockBankDefault
                    setModalClose={() => setModalClose(5)}
                    id={+params.id}
                    incomingBankData={holdUnblockBankData as UnblockBankObject}
                    showAlert={showAlert}
                />
            </Modal>
            <Modal title="Delete Bank Account" showModal={isModalVisible(6)} closeModal={() => setModalClose(6)}>
                <UserDelettingUnblockBankDefault
                    setModalClose={() => setModalClose(6)}
                    id={+params.id}
                    incomingBankData={holdUnblockBankData as UnblockBankObject}
                    showAlert={showAlert}
                />
            </Modal>
            <main className="mx-5">
                <LoaderContainer loading={userIsLoading}>
                    <div className="py-4">
                        <div className="flex items-center justify-between mb-6 header-top">
                            <h1 className="text-2xl font-bold capitalize">
                                {userData?.firstName} {userData?.lastName}
                            </h1>

                            {userData && <CopyButton textToCopy={textToCopyData} extraClass={false} />}
                        </div>
                        <section>
                            <div className="px-5 py-2 mb-5 bg-slate-500">
                                <p className="block text-xl font-bold text-white">Shiga</p>
                            </div>

                            <div className="mb-4">
                                <p className="font-bold">First Name:</p>
                                <p className="">{userData?.firstName}</p>
                            </div>
                            <div className="mb-4">
                                <p className="font-bold">Last Name:</p>
                                <p className="">{userData?.lastName}</p>
                            </div>

                            <div className="mb-4">
                                <p className="font-bold">Date of Birth:</p>
                                <p className=""> {userData && format(new Date(userData?.dob), 'dd MMMM, yyyy')}</p>
                            </div>

                            <div className="mb-4">
                                <p className="font-bold">Email:</p>
                                {userData && (
                                    <EditableText
                                        name="email"
                                        validationSchema={z.object({ email: z.string().email() })}
                                        value={userData.email as string}
                                        onChange={updateUser('email')}
                                    />
                                )}
                            </div>

                            <div className="mb-4">
                                <p className="font-bold">Phone Number:</p>
                                <p className="">{userData?.phoneNumber}</p>
                            </div>

                            <div className="mb-4">
                                <p className="font-bold">NGN BVN:</p>
                                <p className="">{userData?.ngnBvn}</p>
                            </div>

                            <div className="mb-4">
                                <p className="mr-5 font-bold">Wallet Address:</p>
                                {userData && (
                                    <EditableText
                                        name="walletAddress"
                                        validationSchema={walletAddressSchema}
                                        value={userData.walletAddress as string}
                                        onChange={updateUser('walletAddress')}
                                    />
                                )}
                            </div>
                            <div className="mb-4">
                                <p className="font-bold">Solana Wallet Address:</p>
                                {userData && (
                                    <EditableText
                                        name="targetSolanaAddress"
                                        validationSchema={targetSolanaAddressSchema}
                                        value={userData.targetSolanaAddress ?? ''}
                                        onChange={updateUser('targetSolanaAddress')}
                                    />
                                )}
                            </div>

                            <div className="mb-4">
                                <p className="font-bold">Country:</p>
                                <p className="">{userData?.country}</p>
                            </div>
                            <LoaderContainer loading={isLoadingListUserDestinationBankAccounts}>
                                <div className="flex justify-end">
                                    <button onClick={() => setModalOpen(3)} className="btn">
                                        Add Destination Bank Account
                                    </button>
                                </div>
                                <div className="flex mb-5">
                                    <Table
                                        title="Destination Bank Accounts"
                                        subtitle="for off-ramping"
                                        columns={bankDestinationAccts}
                                        data={listUserDestinationBankAccounts || []}
                                    />
                                </div>
                            </LoaderContainer>

                            <LoaderContainer loading={userIsLoading}>
                                <section className="container py-8 mx-auto">
                                    <h2 className="mb-4 text-2xl font-bold">
                                        KYC documents{' '}
                                        <span className="text-xs text-red-600"> (Click images to zoom)</span>
                                    </h2>

                                    <div className="grid grid-cols-2 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
                                        <div className="mr-20 rounded-lg">
                                            <Zoom>
                                                <img
                                                    src={
                                                        configs.s3BaseUrl + userData?.uploadIdUrl ||
                                                        'https://via.placeholder.com/300'
                                                    }
                                                    alt="Image 1"
                                                    className="object-cover w-full h-96"
                                                />{' '}
                                            </Zoom>

                                            <p className="mt-2 text-center text-gray-700">
                                                Mode of Identification:{' '}
                                                <span className="text-blue-600 capitalize">{userData?.idType}</span>
                                            </p>
                                            <p className="mt-2 text-center text-gray-700">
                                                Identification Number: {userData?.idNumber}
                                            </p>
                                        </div>
                                        <div className="rounded-lg ">
                                            <Zoom>
                                                <img
                                                    src={
                                                        configs.s3BaseUrl + userData?.proofOfAddressUrl ||
                                                        'https://via.placeholder.com/300'
                                                    }
                                                    alt="Image 2"
                                                    className="object-cover w-full h-96"
                                                />
                                            </Zoom>

                                            <p className="mt-2 text-center text-gray-700">
                                                Proof Of Address:{' '}
                                                <span className="text-blue-600 capitalize">
                                                    {userData?.addressVerification?.replaceAll('_', ' ')}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="my-7">
                                        <div className="px-5 py-2 bg-slate-500">
                                            <p className="block text-xl font-bold text-white">
                                                KYC Compliance Evaluation
                                            </p>
                                        </div>
                                        <div className="flex items-center justify-between mt-3">
                                            <p>Evaluate and review customer submitted KYC documents</p>
                                            <button className="ml-5 btn btn-outline" onClick={() => setModalOpen(4)}>
                                                Evaluate
                                            </button>
                                        </div>
                                    </div>
                                </section>
                            </LoaderContainer>
                        </section>

                        {/* Switch Tab */}
                        <div className="flex items-center justify-between mt-8 mb-4">
                            <h1 className="text-[24px] leading-6">
                                <span className="font-bold">Other Individual Data</span>{' '}
                                <span className="text-[16px]">user data with partners</span>
                            </h1>
                            <div className="flex gap-3">
                                {activityFilter.map(({ label, value }) => (
                                    <button
                                        onClick={() => setTabValue(value)}
                                        className={`${
                                            value === tabValue ? 'text-white bg-[#374151]' : 'bg-white text-[#374151]'
                                        } px-3 py-2 border-2 border-solid rounded-lg border-[#374151] font-medium`}
                                    >
                                        {label}
                                    </button>
                                ))}
                            </div>
                        </div>
                        {/* Switch Tab */}

                        {tabValue === 'unblock_data' && (
                            <>
                                <section>
                                    <div className="px-5 py-2 mb-5 bg-slate-500">
                                        <p className="block text-xl font-bold text-white">Unblock</p>
                                    </div>
                                    <div tabIndex={0} className="collapse bg-base-200">
                                        <div className="mb-4 text-xl font-bold cursor-pointer collapse-title">
                                            Unblock User Data{' '}
                                            <span className="text-xs text-red-600">(Click to View)</span>
                                        </div>
                                        <div className="p-4 bg-white border border-gray-200 rounded-lg collapse-content">
                                            <LoaderContainer loading={isLoadingUnblockIndividualData}>
                                                <pre className="p-4 font-mono text-sm text-gray-800 bg-gray-100 rounded-lg">
                                                    {JSON.stringify(unblockIndividualData, null, 2)}
                                                </pre>
                                            </LoaderContainer>
                                        </div>
                                    </div>
                                    <hr className="my-5 border-2 border-gray-300" />
                                    <div className="p-4 mb-10 bg-gray-100 rounded-lg shadow-md">
                                        <p className="text-xl font-bold">
                                            Session Last Updated:
                                            <span className="ml-2 text-primary">
                                                {userLastSession?.createdAt
                                                    ? format(
                                                          new Date(userLastSession?.updatedAt),
                                                          'hh:mm a, dd MMMM, yyyy',
                                                      )
                                                    : '-'}
                                                {/* TODO: x hours and y minutes ago */}
                                            </span>
                                            <SessionValidityChecker lastUpdated={userLastSession?.updatedAt} />
                                        </p>
                                        <p>{userLastSession?.sessionId}</p>
                                        <div className="flex justify-between">
                                            <small>
                                                This is needed before making edits to the user. If the 'Session Last
                                                Updated' is more than 4 hours ago, a new OTP needs to be sent.
                                                <br />
                                                First, trigger OTP. Then, check the inbox and enter the OTP here. After
                                                pasting the number, no additional keys need to be pressed.
                                            </small>
                                            <button className="ml-5 btn btn-outline" onClick={triggerOTP}>
                                                {IsOtpprocessing ? 'Processing' : 'Trigger OTP'}
                                            </button>
                                        </div>
                                        <div className="mb-5">
                                            <OtpInput
                                                inputStyle={{
                                                    width: '1.5rem',
                                                    height: '1.5rem',
                                                    margin: '0 1rem',
                                                    fontSize: '1rem',
                                                    borderRadius: '4px',
                                                    border: '1px solid rgba(0, 0, 0, 0.3)',
                                                }}
                                                value={otp}
                                                onChange={handleChange}
                                                numInputs={6}
                                                renderSeparator={<span>-</span>}
                                                renderInput={props => <input {...props} />}
                                            />
                                        </div>
                                    </div>
                                    <hr className="my-5 border-2 border-gray-300" />
                                    <LoaderContainer loading={corporateDataIsLoading}>
                                        <div className="my-5">
                                            <p className="mb-4 text-xl font-bold">Linked Corporate</p>
                                            <ul>
                                                {companyData ? (
                                                    companyData.map(company => (
                                                        <Fragment>
                                                            <li>
                                                                <p className="capitalize">- {company.legalName}</p>
                                                            </li>
                                                        </Fragment>
                                                    ))
                                                ) : (
                                                    <p className="text-gray-600">Not LInked</p>
                                                )}
                                            </ul>
                                        </div>
                                    </LoaderContainer>
                                    <hr className="my-5 border-2 border-gray-300" />
                                    <div className="p-4 mb-10 bg-gray-100 rounded-lg shadow-md">
                                        <p className="mb-4 text-xl">
                                            <span className="font-bold">Create Unblock Bank Account</span>
                                            <span> for on-ramping</span>
                                        </p>
                                        <div>
                                            <p>Currency:</p>
                                            <select
                                                className="p-2 mr-3 border border-gray-300 rounded-md"
                                                value={currency}
                                                onChange={handleChangeCurrency}
                                            >
                                                <option value="EUR">EUR</option>
                                                <option value="GBP">GBP</option>
                                            </select>
                                            <button
                                                className="ml-5 btn btn-outline"
                                                onClick={handleSubmit}
                                                disabled={isLoadingCreatingBankAcct}
                                            >
                                                {isLoadingCreatingBankAcct ? 'Submitting...' : 'Submit'}
                                            </button>
                                        </div>
                                    </div>

                                    <LoaderContainer loading={unblockBankAccountsIsLoading}>
                                        {unblockBankAccounts && isEmpty(unblockBankAccounts) === true ? null : (
                                            <div className="flex justify-end mb-2">
                                                <BankDownloadButton
                                                    bankData={unblockBankAccounts}
                                                    fullName={userData?.firstName + ' ' + userData?.lastName}
                                                    firstName={userData?.firstName as string}
                                                />
                                            </div>
                                        )}
                                        <div className="flex mb-5">
                                            <Table
                                                title="Unblock Bank Accounts"
                                                subtitle="for on-ramping"
                                                columns={unblockBankAccountsColumns}
                                                data={unblockBankAccounts || []}
                                            />
                                        </div>
                                    </LoaderContainer>
                                    <LoaderContainer loading={isLoadingTokenPreferences}>
                                        <div className="flex flex-col my-5">
                                            {!isLoadingTokenPreferences && (
                                                <Table
                                                    enableActions
                                                    onClickSave={onClickSaveTokenPreferences}
                                                    title="Unblock Token Preferences"
                                                    subtitle="for on-ramping"
                                                    columns={tokenPreferencesColumn}
                                                    data={tokenPreferences}
                                                />
                                            )}
                                        </div>
                                    </LoaderContainer>

                                    <hr className="my-5 border-2 border-gray-300" />

                                    <LoaderContainer loading={unblockWalletsLoading}>
                                        {unblockWallets && isEmpty(unblockWallets) === true ? null : (
                                            <div className="flex justify-end mt-10">
                                                <WalletDownloadButton
                                                    wallData={unblockWallets as GroupedWalletData[]}
                                                    firstName={userData?.firstName as string}
                                                    fullName={userData?.firstName + ' ' + userData?.lastName}
                                                />
                                            </div>
                                        )}
                                        <div className="flex">
                                            <Table
                                                title="Unblock Wallets"
                                                subtitle="for off-ramping"
                                                columns={unblockWalletColumns}
                                                data={unblockWallets || []}
                                            />
                                        </div>
                                    </LoaderContainer>
                                    <LoaderContainer
                                        loading={uIsLoadingUnblockDesBankAcct || isRefetchingUnblockDesBankAcct}
                                    >
                                        <div className="flex mb-5">
                                            <Table
                                                title="Unblock Destination Bank Accounts"
                                                subtitle="for off-ramping"
                                                columns={bankDestinationAccounts}
                                                data={unblockDestBankAcct || []}
                                                enableActions
                                                useCombinedAction
                                                onRowView={data => {
                                                    setModalOpen(5);
                                                    setHoldUnblockBankData(data);
                                                }}
                                                onRowDelete={data => {
                                                    setModalOpen(6);
                                                    setHoldUnblockBankData(data);
                                                }}
                                            />
                                        </div>
                                    </LoaderContainer>

                                    <hr className="my-5 border-2 border-gray-300" />

                                    <LoaderContainer loading={transactionIsLoading}>
                                        <div className="flex mb-5">
                                            {!transactionIsLoading && (
                                                <Table
                                                    title="Unblock Transactions"
                                                    columns={transactionColumn}
                                                    data={sortTransactionData || []} // sort by created_at
                                                />
                                            )}
                                        </div>
                                    </LoaderContainer>
                                </section>
                                <LoaderContainer loading={isLoadingkycUrl && isFetchingkycUrl}>
                                    <div className="mb-4">
                                        <p className="font-bold">Unblock KYC URL:</p>
                                        <div className="mb-5 text-gray-600">
                                            {kycUrl ? (
                                                <CopyToClipboardInput url={kycUrl} />
                                            ) : (
                                                <button onClick={onCreateUnblockKycApplication} className="btn">
                                                    {isCreateUnblockKycApp ? 'Submitting' : 'Create kyc application'}
                                                </button>
                                            )}
                                        </div>
                                        <p className="font-bold">KYC Status:</p>
                                        <p className="text-gray-600">
                                            {kycApplication?.status
                                                ? kycApplication?.status?.replaceAll('_', ' ')
                                                : 'N/A (kyc application not yet created)'}
                                        </p>
                                        {kycApplication?.kyc_enduser_error_message && (
                                            <p className="text-red-600">{kycApplication?.kyc_enduser_error_message}</p>
                                        )}
                                    </div>
                                </LoaderContainer>
                                <span className="font-bold">Upload Additional KYC Documents</span>
                                <UploadKycDoc userId={params.id} />
                                <LoaderContainer loading={isLoadingUnblockIndividualData}>
                                    <div className="mt-[30px]">
                                        <Table
                                            title="Custom Fee Config"
                                            subtitle=""
                                            columns={customFeeConfig}
                                            data={unblockIndividualData?.customFeeConfig || []}
                                        />
                                    </div>
                                </LoaderContainer>
                                <section className="mt-6 feeConfig">
                                    <div className="feeConfig-content bg-[#f9fafb] pb-5 rounded-lg shadow-md">
                                        <div className="content-title w-full bg-[#1f2937] px-4 py-2.5">
                                            <h2 className="text-base font-bold text-gray-300">
                                                Fee Operation Configuration
                                            </h2>
                                        </div>
                                        <div className="w-full py-5 content-form px-9">
                                            <form onSubmit={handleConfigSubmit}>
                                                <div className="flex flex-wrap items-center justify-start mb-2 content-form-double">
                                                    <label className="text-base font-semibold text-gray-700 w-[158px] mr-7 my-4">
                                                        Operation Direction:
                                                        <select
                                                            value={direction}
                                                            onChange={e => setDirection(e.target.value as Direction)}
                                                            className="mt-2 w-full block border-[1.5px] bg-white font-normal border-gray-300 focus:outline-none focus:ring-[#5f9dfb] focus:border-[#5f9dfb] rounded-md py-2 px-3"
                                                        >
                                                            <option value="" disabled>
                                                                Select
                                                            </option>
                                                            <option value="ONRAMP">ONRAMP</option>
                                                            <option value="OFFRAMP">OFFRAMP</option>
                                                        </select>
                                                    </label>

                                                    <label className="text-base font-semibold text-gray-700 w-[188px] mr-7 my-4">
                                                        Operation Type:
                                                        <select
                                                            value={feeOpType}
                                                            onChange={e => setFeeOpType(e.target.value as FeeOpt)}
                                                            className="mt-2 w-full block border-[1.5px] bg-white font-normal border-gray-300 focus:outline-none focus:ring-[#5f9dfb] focus:border-[#5f9dfb] rounded-md py-2 px-3"
                                                        >
                                                            <option value="" disabled>
                                                                Select
                                                            </option>
                                                            <option value="add">Normal Fee </option>
                                                            <option value="subtract">Discount Fee by </option>
                                                        </select>
                                                    </label>

                                                    <label className="text-base font-semibold text-gray-700 w-[124px] mr-7">
                                                        Input Currency:
                                                        <select
                                                            value={inputCurrency}
                                                            onChange={e => setInputCurrency(e.target.value as Currency)}
                                                            className="mt-2 w-full block border-[1.5px] bg-white font-normal border-gray-300 focus:outline-none focus:ring-[#5f9dfb] focus:border-[#5f9dfb] rounded-md py-2 px-3"
                                                        >
                                                            <option value="" disabled>
                                                                Select
                                                            </option>
                                                            <option value="EUR">EUR</option>
                                                            <option value="USD">USD</option>
                                                            <option value="GBP">GBP</option>
                                                        </select>
                                                    </label>

                                                    <label className="text-base font-semibold text-gray-700 w-[188px] mr-7 my-4">
                                                        Operation Fee:
                                                        <input
                                                            type="number"
                                                            placeholder="0.01"
                                                            step="0.0001"
                                                            value={fee}
                                                            onChange={e => setFee(e.target.value)}
                                                            className="mt-2 block w-full border-[1.5px] bg-white font-normal border-gray-300 focus:outline-none focus:ring-[#5f9dfb] focus:border-[#5f9dfb] rounded-md py-1.5 px-3"
                                                            required
                                                        />
                                                        <p className="absolute text-xs font-medium text-gray-700">
                                                            {' '}
                                                            e.g.: 0.01 corresponds to 1%{' '}
                                                        </p>
                                                    </label>

                                                    <label className="text-base font-semibold text-gray-700 w-[138px] my-4">
                                                        Output Currency:
                                                        <select
                                                            value={outputCurrency}
                                                            onChange={e =>
                                                                setOutputCurrency(e.target.value as Currency)
                                                            }
                                                            className="mt-2 w-full block border-[1.5px] bg-white font-normal border-gray-300 focus:outline-none focus:ring-[#5f9dfb] focus:border-[#5f9dfb] rounded-md py-2 px-3"
                                                        >
                                                            <option value="" disabled>
                                                                Select
                                                            </option>
                                                            <option value="EUR">EUR</option>
                                                            <option value="USD">USD</option>
                                                            <option value="GBP">GBP</option>
                                                        </select>
                                                    </label>
                                                </div>

                                                <div className="flex items-center justify-end mt-4 content-form-btns">
                                                    <button
                                                        type="submit"
                                                        className="border-2 border-solid rounded-lg border-[#374151] text-white bg-[#374151] px-3 py-2 mr-3.5 font-medium hover:bg-white hover:text-[#374151] hover:font-semibold"
                                                    >
                                                        {isLoading ? 'Configuring....' : 'Configure Fee'}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </section>
                            </>
                        )}

                        {tabValue === 'bridge_data' && <UserBridgeSection />}
                    </div>
                </LoaderContainer>
            </main>
        </div>
    );
}

export default UserView;
