import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Event } from '../../components/alert';
import { trpc } from '../../lib/trpc';

type FormData = {
    account_type: string;
    bank_name: string;
    account_name?: string;
    account_owner_name: string;
    iban?: {
        account_number: string;
        bic: string;
        country: string; // ISO 3166 country code
    };
    account?: {
        account_number: string;
        routing_number: string;
        checking_or_savings?: 'checking' | 'savings';
    };
};

export default function ExternalAccountForm({
    showAlert,
    closeModal,
    type,
    refetch
}: {
    showAlert: (message: string, type: Event, delay?: number) => void;
    closeModal: () => void;
    type: 'individual' | 'corporate';
    refetch: () => void;
}) {
    const params = useParams<{ id: string }>() as { id: string };

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        reset,
    } = useForm<FormData>();

    const { mutate, isLoading } = trpc.admin.adminAddCustomerExternalAccount.useMutation({
        onSuccess: res => {
            showAlert(res.message, 'success');
            refetch();
            closeModal();
            reset();
        },
        onError: err => {
            showAlert(err.message, 'error');
        },
    });

    const onSubmit = (data: FormData) => {
        mutate({
            type: type,
            id: +params.id,
            account_type: data.account_type as 'iban' | 'us',
            account_name: data.account_name,
            bank_name: data.bank_name,
            account_owner_name: data.account_owner_name,
            account: {
                account_number: data?.account?.account_number as string,
                routing_number: data?.account?.routing_number as string,
                checking_or_savings: data?.account?.checking_or_savings as 'checking' | 'savings',
            },
            account_owner_type: type === 'corporate' ? 'business' : 'individual',
        });
    };

    const account_type = watch('account_type');
    return (
        <div className="">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4">
                    <div>
                        <label htmlFor="account_type" className="block text-sm font-medium text-gray-700">
                            Account Type
                        </label>
                        <select
                            {...register('account_type', { required: 'Account type is required' })}
                            id="account_type"
                            className={`border-[#0e2038] w-full border-[1px] mt-2 outline-none accent-[#0e2038] cursor-pointer p-3 rounded-lg`}
                        >
                            <option value="" disabled selected>
                                Please select
                            </option>
                            <option value="us">US</option>
                            {/* <option value="iban">IBAN</option> */}
                        </select>
                        {errors.account_type && (
                            <p className="mt-1 text-sm text-red-600">{errors.account_type.message}</p>
                        )}
                    </div>
                    <div>
                        <label
                            htmlFor="account.checking_or_savings"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Checking or Savings{' '}
                        </label>
                        <select
                            {...register('account.checking_or_savings', {
                                required: 'Checking or savings is required',
                            })}
                            id="account.checking_or_savings"
                            className={`border-[#0e2038] w-full border-[1px] mt-2 outline-none accent-[#0e2038] cursor-pointer p-3 rounded-lg`}
                        >
                            <option value="" disabled selected>
                                Please select
                            </option>
                            <option value="checking">Checking</option>
                            <option value="savings">Savings</option>
                        </select>
                        {errors.account?.checking_or_savings && (
                            <p className="mt-1 text-sm text-red-600">{errors.account?.checking_or_savings.message}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="bank_name" className="block text-sm font-medium text-gray-700">
                            Bank Name
                        </label>
                        <input
                            {...register('bank_name', { required: 'Bank name is required' })}
                            type="text"
                            id="bank_name"
                            className={`border-[#0e2038] border-[1px] mt-2 outline-none accent-[#0e2038] cursor-pointer p-3 rounded-lg`}
                        />
                        {errors.bank_name && <p className="mt-1 text-sm text-red-600">{errors.bank_name.message}</p>}
                    </div>

                    <div>
                        <label htmlFor="account_name" className="block text-sm font-medium text-gray-700">
                            Account Name (Optional)
                        </label>
                        <input
                            {...register('account_name')}
                            type="text"
                            id="account_name"
                            className={`border-[#0e2038] border-[1px] mt-2 outline-none accent-[#0e2038] cursor-pointer p-3 rounded-lg`}
                        />
                    </div>

                    <div>
                        <label htmlFor="account_owner_name" className="block text-sm font-medium text-gray-700">
                            Account Owner Name
                        </label>
                        <input
                            {...register('account_owner_name', { required: 'Account owner name is required' })}
                            type="text"
                            id="account_owner_name"
                            className={`border-[#0e2038] border-[1px] mt-2 outline-none accent-[#0e2038] cursor-pointer p-3 rounded-lg`}
                        />
                        {errors.account_owner_name && (
                            <p className="mt-1 text-sm text-red-600">{errors.account_owner_name.message}</p>
                        )}
                    </div>
                    {account_type === 'us' && (
                        <div className="space-y-4">
                            <div>
                                <label
                                    htmlFor="account.account_number"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Account Number
                                </label>
                                <input
                                    {...register('account.account_number', { required: 'Account number is required' })}
                                    type="text"
                                    id="account.account_number"
                                    className={`border-[#0e2038] border-[1px] mt-2 outline-none accent-[#0e2038] cursor-pointer p-3 rounded-lg`}
                                />
                                {errors.account?.account_number && (
                                    <p className="mt-1 text-sm text-red-600">
                                        {errors.account?.account_number.message}
                                    </p>
                                )}
                            </div>

                            <div>
                                <label htmlFor="routing_number" className="block text-sm font-medium text-gray-700">
                                    Routing Number
                                </label>
                                <input
                                    {...register('account.routing_number', { required: 'Routing number is required' })}
                                    type="text"
                                    id="account.routing_number"
                                    className={`border-[#0e2038] border-[1px] mt-2 outline-none accent-[#0e2038] cursor-pointer p-3 rounded-lg`}
                                />
                                {errors.account?.routing_number && (
                                    <p className="mt-1 text-sm text-red-600">
                                        {errors.account?.routing_number.message}
                                    </p>
                                )}
                            </div>
                        </div>
                    )}

                    {account_type === 'iban' && (
                        <div className="space-y-2">
                            <div>
                                <label
                                    htmlFor="iban.account_number"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    International Bank Account Number (IBAN){' '}
                                </label>
                                <input
                                    {...register('iban.account_number', { required: 'Account number is required' })}
                                    type="text"
                                    id="account_number"
                                    className={`border-[#0e2038] border-[1px] mt-2 outline-none accent-[#0e2038] cursor-pointer p-3 rounded-lg`}
                                />
                                {errors.iban?.account_number && (
                                    <p className="mt-1 text-sm text-red-600">{errors.iban?.account_number.message}</p>
                                )}
                            </div>

                            <div>
                                <label htmlFor="routing_number" className="block text-sm font-medium text-gray-700">
                                    Bank Identifier Code (BIC)
                                </label>
                                <input
                                    {...register('iban.bic', { required: 'Routing number is required' })}
                                    type="text"
                                    id="routing_number"
                                    className={`border-[#0e2038] border-[1px] mt-2 outline-none accent-[#0e2038] cursor-pointer p-3 rounded-lg`}
                                />
                                {errors.iban?.bic && (
                                    <p className="mt-1 text-sm text-red-600">{errors.iban?.bic.message}</p>
                                )}
                            </div>
                            <div>
                                <label htmlFor="iban.country" className="block text-sm font-medium text-gray-700">
                                    Country
                                </label>
                                <input
                                    {...register('iban.country', { required: 'Country is required' })}
                                    type="text"
                                    id="iban.country"
                                    className={`border-[#0e2038] border-[1px] mt-2 outline-none accent-[#0e2038] cursor-pointer p-3 rounded-lg`}
                                />
                                {errors.iban?.country && (
                                    <p className="mt-1 text-sm text-red-600">{errors.iban.country.message}</p>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <button
                    type="submit"
                    className="flex justify-center w-full px-4 py-3 mt-10 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    {isLoading ? 'Submitting....' : 'Submit'}
                </button>
            </form>
        </div>
    );
}
