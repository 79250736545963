/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, SyntheticEvent, useEffect, useRef, useState } from 'react';
import useAlert from '../../hooks/useAlert';
import Alert from '../alert';
import './index.css';
import TableCell from './tableCell';

interface Option {
    value: string;
    label: string;
}

type Column = {
    label: string;
    key: string;
    format?: (v1: any, v2?: any) => any;
    meta?: {
        type?: string;
        options?: Option[];
    } | null;
    className?: string | Function;
};

type TableProps = {
    enableActions?: boolean;
    useDelete?: boolean;
    useReport?: boolean;
    useView?: boolean;
    title: string;
    clickable?: boolean;
    subtitle?: string;
    columns: Column[];
    data: any[];
    onRowClick?: (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: any) => void;
    onClickSave?: (row: any) => void;
    onRowDelete?: (row: any) => void;
    onRowReport?: (row: any) => void;
    onRowView?: (row: any) => void;
    useThreeDots?: boolean;
    useCombinedAction?: boolean;
};

export const SaveIcon = ({ onClick }: { onClick?: (e: SyntheticEvent) => void }) => (
    <svg
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6"
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
    </svg>
);

export const EditIcon = ({ onClick }: { onClick: (e: SyntheticEvent) => void }) => (
    <svg
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6"
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
        />
    </svg>
);
export const DeleteIcon = ({ onClick }: { onClick: (e: SyntheticEvent) => void }) => (
    <svg onClick={onClick} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 6H5H21" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z"
            stroke="red"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path d="M10 11V17" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M14 11V17" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);

export const ReportIcon = ({ onClick, rowData }: { onClick: (e: SyntheticEvent) => void; rowData: any }) => {
    const icon = () => {
        switch (rowData.status.toLowerCase()) {
            case 'failed':
                return (
                    <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                            {' '}
                            <path
                                opacity="0.5"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M6.5 1.75C6.5 1.33579 6.16421 1 5.75 1C5.33579 1 5 1.33579 5 1.75V21.75C5 22.1642 5.33579 22.5 5.75 22.5C6.16421 22.5 6.5 22.1642 6.5 21.75V13.6V3.6V1.75Z"
                                fill="#f00f0f"
                            ></path>{' '}
                            <path
                                d="M13.3486 3.78947L13.1449 3.70801C11.5821 3.08288 9.8712 2.9258 8.22067 3.25591L6.5 3.60004V13.6L8.22067 13.2559C9.8712 12.9258 11.5821 13.0829 13.1449 13.708C14.8385 14.3854 16.7024 14.5119 18.472 14.0695L18.6864 14.0159C19.3115 13.8597 19.75 13.298 19.75 12.6538V5.28673C19.75 4.50617 19.0165 3.93343 18.2592 4.12274C16.628 4.53055 14.9097 4.41393 13.3486 3.78947Z"
                                fill="#f00f0f"
                            ></path>{' '}
                        </g>
                    </svg>
                );
            case 'in_progress':
                return (
                    <svg
                        fill="#5c6ce6"
                        height="23px"
                        width="40px"
                        version="1.1"
                        id="XMLID_103_"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        stroke="#5c6ce6"
                    >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                            <g id="in-progress">
                                <g>
                                    <path d="M23,24H1v-2h2.4c-1.6-5,1.6-7,3.7-8.4C8,13,8.9,12.5,8.9,12S8,10.9,7.1,10.4C5,9,1.8,7.1,3.4,2H1V0h22v2h-2.4 c1.6,5-1.6,7-3.7,8.4c-1,0.5-1.9,1.1-1.9,1.6s0.9,1.1,1.8,1.6c2.1,1.4,5.3,3.4,3.7,8.4H23V24z M5.6,22h12.8c1.6-4-0.5-5.3-2.6-6.7 C14.4,14.5,13,13.6,13,12c0-1.6,1.4-2.5,2.8-3.3C17.9,7.3,20,6,18.4,2H5.6C4,6,6.1,7.3,8.2,8.7C9.6,9.5,11,10.4,11,12 c0,1.6-1.4,2.5-2.8,3.3C6.1,16.7,4,18,5.6,22z"></path>
                                </g>
                                <g>
                                    <path d="M16.8,23H7c-0.3-1.5,0.2-2.4,2.3-4.3c0.8-0.7,1.8-1.5,2.7-2.8c1,1.2,2,2.1,2.7,2.8C16.8,20.7,17.3,21,16.8,23z"></path>
                                </g>
                                <g>
                                    <path d="M9.4,6c-0.7,1.3-0.7,1.3,0.9,2.1c0.5,0.2,1.1,0.5,1.6,0.9c0.5-0.4,1.2-0.7,1.6-0.9c1.7-0.8,1.7-0.8,1-2.1"></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                );
            default:
                return (
                    <svg
                        width="39px"
                        height="39px"
                        viewBox="0 0 72 72"
                        id="emoji"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#11d428"
                        stroke="#11d428"
                    >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                            <g id="color">
                                <polygon
                                    fill="#4de723"
                                    stroke="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-miterlimit="10"
                                    stroke-width="2"
                                    points="54.5737,12.7708 35.5,40.3333 25.1667,30.3333 25.1667,35.25 35.875,45.75 54.5737,18.625"
                                ></polygon>
                            </g>
                            <g id="hair"></g>
                            <g id="skin"></g>
                            <g id="skin-shadow"></g>
                            <g id="line">
                                <path
                                    fill="none"
                                    stroke="#2be212"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-miterlimit="10"
                                    stroke-width="2"
                                    d="M52.0844,28.991C52.9959,31.1452,53.5,33.5137,53.5,36c0,9.9411-8.0589,18-18,18s-18-8.0589-18-18s8.0589-18,18-18 c3.2668,0,6.3303,0.8703,8.9714,2.3916"
                                ></path>
                                <polygon
                                    fill="none"
                                    stroke="#2be212"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-miterlimit="10"
                                    stroke-width="2"
                                    points="54.5737,12.7708 35.5,40.3333 25.1667,30.3333 25.1667,35.25 35.875,45.75 54.5737,18.625"
                                ></polygon>
                            </g>
                        </g>
                    </svg>
                );
        }
    };

    return (
        <div onClick={onClick} className="w-4 h-4">
            {icon()}
        </div>
    );
};

export const ViewIcon = ({ onClick, rowData }: { onClick?: (e: SyntheticEvent) => void; rowData?: any }) => (
    <svg
        fill={rowData?.status === "UNREAD" ? "#000000" : "#11D428"}
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        width="25px"
        height="25px"
        viewBox="0 0 442.04 442.04"
        onClick={onClick}
    >
        <g>
            <g>
                <path
                    d="M221.02,341.304c-49.708,0-103.206-19.44-154.71-56.22C27.808,257.59,4.044,230.351,3.051,229.203
			c-4.068-4.697-4.068-11.669,0-16.367c0.993-1.146,24.756-28.387,63.259-55.881c51.505-36.777,105.003-56.219,154.71-56.219
			c49.708,0,103.207,19.441,154.71,56.219c38.502,27.494,62.266,54.734,63.259,55.881c4.068,4.697,4.068,11.669,0,16.367
			c-0.993,1.146-24.756,28.387-63.259,55.881C324.227,321.863,270.729,341.304,221.02,341.304z M29.638,221.021
			c9.61,9.799,27.747,27.03,51.694,44.071c32.83,23.361,83.714,51.212,139.688,51.212s106.859-27.851,139.688-51.212
			c23.944-17.038,42.082-34.271,51.694-44.071c-9.609-9.799-27.747-27.03-51.694-44.071
			c-32.829-23.362-83.714-51.212-139.688-51.212s-106.858,27.85-139.688,51.212C57.388,193.988,39.25,211.219,29.638,221.021z"
                />
            </g>
            <g>
                <path
                    d="M221.02,298.521c-42.734,0-77.5-34.767-77.5-77.5c0-42.733,34.766-77.5,77.5-77.5c18.794,0,36.924,6.814,51.048,19.188
			c5.193,4.549,5.715,12.446,1.166,17.639c-4.549,5.193-12.447,5.714-17.639,1.166c-9.564-8.379-21.844-12.993-34.576-12.993
			c-28.949,0-52.5,23.552-52.5,52.5s23.551,52.5,52.5,52.5c28.95,0,52.5-23.552,52.5-52.5c0-6.903,5.597-12.5,12.5-12.5
			s12.5,5.597,12.5,12.5C298.521,263.754,263.754,298.521,221.02,298.521z"
                />
            </g>
            <g>
                <path d="M221.02,246.021c-13.785,0-25-11.215-25-25s11.215-25,25-25c13.786,0,25,11.215,25,25S234.806,246.021,221.02,246.021z" />
            </g>
        </g>
    </svg>
);

export const ReplySvg = ({ onClick, rowData }: { onClick: () => void; rowData: any  }) => (
    <svg height="20px" width="30px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512" onClick={onClick}>
        <g>
            <polygon fill={rowData?.resolution === 'UNRESOLVED' ? '#F4B2B0' : "#11D428"} points="348.632,315.499 310.369,347.136 272.106,315.499 268.721,315.499 123.386,435.666 
		123.386,439.058 497.352,439.058 497.352,435.666 352.017,315.499 	"/>
            <polygon  fill={rowData?.resolution === 'UNRESOLVED' ? '#F4B2B0' : "#11D428"} points="219.929,151.439 219.929,187.235 123.386,187.235 123.386,192.532 310.369,347.136 
		497.353,192.532 497.353,151.439 	"/>
        </g>
        <path fill="#B3404" d="M506.686,203.819c3.366-2.783,5.314-6.922,5.314-11.288v-41.093c0-8.088-6.559-14.647-14.647-14.647
	H234.575v-21.15c0-8.088-6.557-14.647-14.647-14.647h-82.274V72.939c0-5.504-3.086-10.543-7.988-13.046
	c-4.902-2.499-10.793-2.045-15.252,1.185L6.054,139.576C2.251,142.331,0,146.743,0,151.439c0,4.696,2.251,9.107,6.054,11.862
	l102.684,74.388v112.376c0,8.088,6.557,14.647,14.647,14.647s14.647-6.559,14.647-14.647V223.647L247.427,314.1L114.052,424.379
	c-3.366,2.783-5.314,6.922-5.314,11.288v3.392c0,8.088,6.557,14.647,14.647,14.647H497.35l0,0l0,0
	c8.088,0,14.647-6.559,14.647-14.647V281.063c0-8.088-6.559-14.647-14.647-14.647s-14.647,6.559-14.647,14.647v123.49L373.308,314.1
	L506.686,203.819z M234.576,166.085h248.13v19.552L339.308,304.203c-0.003,0.003-0.006,0.004-0.009,0.007l-28.93,23.921
	l-28.93-23.921c-0.001-0.001-0.003-0.003-0.004-0.004L157.683,201.885h62.245c5.561,0,10.399-3.101,12.879-7.666
	c1.128-2.075,1.768-4.454,1.768-6.982v-21.151H234.576z M108.36,201.241l-68.748-49.802l68.748-49.802v14.005
	c0,8.088,6.557,14.647,14.647,14.647h82.274v42.301h-81.895c-0.01,0-0.019,0.001-0.028,0.001h-0.35
	c-8.089,0-14.647,6.559-14.647,14.647v14.002H108.36z M460.757,424.413H159.983l110.431-91.308l30.622,25.32
	c0.338,0.28,0.687,0.542,1.043,0.787c2.493,1.714,5.392,2.572,8.29,2.572c2.899,0,5.797-0.858,8.29-2.572
	c0.356-0.245,0.704-0.507,1.043-0.787l30.624-25.321L460.757,424.413z"/>
    </svg>
)

export const ThreeDots = ({ onClick }: { onClick: () => void }) => (
    <svg
        width="20px"
        height="15px"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        fill="#000000"
        onClick={onClick}
    >
        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
    </svg>
);   



export default function Table ({
    title,
    subtitle = '',
    columns = [],
    data = [],
    clickable,
    onRowClick,
    enableActions,
    onClickSave,
    useDelete = false,
    onRowDelete,
    useReport,
    onRowReport,
    useView,
    onRowView,
    useThreeDots,
    useCombinedAction
}: TableProps): JSX.Element {
    const { alert, showAlert } = useAlert();
    const [editRowIndex, setEditRowIndex] = useState(-1);
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const [modifiedData, setModifiedData] = useState<any>({});

    useEffect(() => {
        if (editRowIndex !== -1) {
            inputRefs.current[editRowIndex]?.focus();
        }
    }, [editRowIndex]);

    const onChangeInput = (e: SyntheticEvent, key: string, rowIndex: number) => {
        const { value } = e.target as typeof e.target & {
            value: string;
        };

        setModifiedData((prev: any) => ({
            [rowIndex]: {
                ...data[rowIndex],
                ...prev[rowIndex],
                [key]: value,
            },
        }));
    };
    const handleEditClick = (rowIndex: number) => {
        setEditRowIndex(rowIndex);
    };

    const onClickSaveInternal = (_: any, rowIndex: number) => {
        try {
            setEditRowIndex(-1);
            onClickSave?.(modifiedData[rowIndex] ?? data[rowIndex]);
            setModifiedData((prev: any) => {
                const newData = { ...prev };
                delete newData[rowIndex];
                return newData;
            });
        } catch (error) {
            showAlert('Edit Failed', 'error', 10000);
        }
    };

    return (
        <Fragment>
            {alert && <Alert event={alert?.type} classes={'w-[20%] ml-auto'} message={alert.message} />}
            <div className="overflow-x-auto">
                <h1 className="text-xl">
                    <span className="mb-4 font-bold">{title}</span> {subtitle}
                </h1>
                <table className="table table-xs">
                    <thead className="text-xs text-gray-100 uppercase bg-gray-700">
                        <tr>
                            {columns.map((column: Column) => {
                                return (
                                    <th scope="col" className="px-6 py-3" key={column.key}>
                                        {column.label}
                                    </th>
                                );
                            })}
                            {enableActions && (
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {data.length === 0 ? (
                            <tr>
                                <td colSpan={columns.length + 1} className="text-center text-[18px] py-4">
                                    {`No ${title} found`}
                                </td>
                            </tr>
                        ) : (
                            data?.map((row: any, rowIndex: number) => (
                                <tr
                                    className={`odd:bg-white even:bg-gray-5 cursor-pointer ${
                                        clickable ? 'hover:bg-[#f3f4f6]' : ''
                                    }`}
                                    key={rowIndex}
                                >
                                    {columns.map((column: Column, colIndex: number) => {
                                        const value = column.key.split('.').reduce((obj, key) => obj?.[key], row);
                                        const cellClass =
                                            typeof column.className === 'function'
                                                ? column.className(value)
                                                : column.className;

                                        return (
                                            <td
                                                onClick={(e: any) => editRowIndex !== rowIndex && onRowClick?.(e, row)}
                                                className="px-6 py-4"
                                                key={colIndex}
                                            >
                                                {(editRowIndex === rowIndex && column.meta) ||
                                                column?.meta?.type === 'image' ? (
                                                    <TableCell
                                                        value={value}
                                                        columnMeta={column.meta}
                                                        rowIndex={rowIndex}
                                                        onChangeInput={e => onChangeInput(e, column.key, rowIndex)}
                                                    />
                                                ) : column?.format ? (
                                                    <span className={cellClass}>{column?.format(value)}</span>
                                                ) : (
                                                    <span className={cellClass}>{String(value ?? '')}</span>
                                                )}
                                            </td>
                                        );
                                    })}
                                    {enableActions && (
                                        <td className="px-6 py-4">
                                            {useDelete ? (
                                                <DeleteIcon onClick={() => onRowDelete?.(row)} />
                                            ) : useReport ? (
                                                <ReportIcon onClick={() => onRowReport?.(row)} rowData={row} />
                                            ) : editRowIndex === rowIndex ? (
                                                <SaveIcon onClick={() => onClickSaveInternal(row, rowIndex)} />
                                            ) : useView ? (
                                                <div className="flex items-center gap-2">
                                                    <ViewIcon onClick={() => onRowReport?.(row)} rowData={row} />
                                                    <ReplySvg onClick={() => onRowView?.(row)} rowData={row} />
                                                </div>
                                            ) : useThreeDots ? (
                                                <ThreeDots onClick={() => onRowView?.(row)} />
                                            ) : useCombinedAction ? (
                                                <div className="flex items-center gap-2">
                                                    <ThreeDots onClick={() => onRowView?.(row)} />
                                                    <DeleteIcon onClick={() => onRowDelete?.(row)} />
                                                </div>
                                            ) : (
                                                <EditIcon onClick={() => handleEditClick(rowIndex)} />
                                            )}
                                        </td>
                                    )}
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </Fragment>
    );
}
