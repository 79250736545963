/* eslint-disable @typescript-eslint/no-explicit-any */
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import { Event } from '../../components/alert';
import { trpc } from '../../lib/trpc';
import { validateBase64Image } from '../../utils/isValidBase64Image';

const UserFormForBridgeSchema = z.object({
    tax_identification_number: z.string().min(1, { message: 'Tax Identification Number is required' }),
    gov_id_image_front: z
        .instanceof(FileList, { message: 'Government ID image is required and must be a valid file' })
        .refine(
            fileList => {
                const file = fileList[0];
                return file.size <= 10 * 1024 * 1024; // Check file size <= 10MB
            },
            {
                message: 'File size should be less than 10MB.',
            },
        )
        .refine(
            fileList => {
                const file = fileList[0];
                return file.type.startsWith('image/') || ['application/pdf'].includes(file.type);
            },
            {
                message: 'Invalid file type. Only images and PDF files are allowed.',
            },
        )
        .refine(
            fileList => {
                const file = fileList[0];
                return new Promise<boolean>((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        const base64Image = reader.result as string;
                        resolve(validateBase64Image(base64Image));
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                });
            },
            {
                message: 'Invalid Base64 image format or size.',
            },
        )
        .optional(),
    proof_of_address_document: z
        .instanceof(FileList, { message: 'Proof of address is required and must be a valid file' })
        .refine(
            fileList => {
                const file = fileList[0];
                return file.size <= 10 * 1024 * 1024; // Check file size <= 10MB
            },
            {
                message: 'File size should be less than 10MB.',
            },
        )
        .refine(
            fileList => {
                const file = fileList[0];
                return file.type.startsWith('image/') || ['application/pdf'].includes(file.type);
            },
            {
                message: 'Invalid file type. Only images and PDF files are allowed.',
            },
        )
        .refine(
            fileList => {
                const file = fileList[0];
                return new Promise<boolean>((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        const base64Image = reader.result as string;
                        resolve(validateBase64Image(base64Image));
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                });
            },
            {
                message: 'Invalid Base64 image format or size.',
            },
        )
        .optional(),
});

type UserFormForBridgeData = z.infer<typeof UserFormForBridgeSchema>;

function UserFormForBridge({
    showAlert,
    closeModal,
}: {
    showAlert: (message: string, type: Event, delay?: number) => void;
    closeModal: () => void;
}) {
    const params = useParams<{ id: string }>() as { id: string };

    const {
        register,
        handleSubmit,
        formState: { isSubmitting },
    } = useForm({
        defaultValues: {
            tax_identification_number: '',
            gov_id_image_front: undefined,
            proof_of_address_document: undefined,
        },
        resolver: zodResolver(UserFormForBridgeSchema),
    });

    const { mutate, isLoading } = trpc.admin.adminAddCustomerBridgeAccount.useMutation({
        onSuccess: res => {
            if (res.success) {
                showAlert(res.message, 'success');
                closeModal();
            }
        },
        onError: e => {
            showAlert(e?.message, 'error');
        },
    });

    const fileToDataUriWEAK = (file: FileList): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = event => {
                if (event.target?.result) {
                    resolve(event.target.result as string);
                } else {
                    reject(new Error('Failed to convert file to data URI'));
                }
            };
            reader.readAsDataURL(file['0'] as File);
        });
    };

    const handleOnSubmit = async (data: UserFormForBridgeData) => {
        const gov_id_image_front = await fileToDataUriWEAK(data?.gov_id_image_front as unknown as FileList);

        const proof_of_address_document = await fileToDataUriWEAK(
            data?.proof_of_address_document as unknown as FileList,
        );

        mutate({
            tax_identification_number: data.tax_identification_number,
            type: 'individual',
            id: Number(params.id),
            gov_id_image_front,
            proof_of_address_document,
        });
    };

    return (
        <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="space-y-4">
                <div>
                    <label htmlFor="tax_identification_number">Tax Identification Number</label>
                    <input
                        type="text"
                        {...register('tax_identification_number', { required: true })}
                        className={`border-[#0e2038] border-[1px] mt-2 outline-none accent-[#0e2038] cursor-pointer p-3 rounded-lg`}
                        placeholder="eg: 1234567890TR"
                    />
                </div>
                <div>
                    <label htmlFor="gov_id_image_front" className="block text-sm font-medium text-gray-700">
                        Government ID Image (Front)
                    </label>
                    <input
                        {...register('gov_id_image_front', { required: true })}
                        type="file"
                        accept="image/*"
                        className="block w-full mt-1"
                    />
                </div>
                <div>
                    <label htmlFor="gov_id_image_front" className="block text-sm font-medium text-gray-700">
                        Proof of Address
                    </label>
                    <input
                        {...register('proof_of_address_document', { required: true })}
                        type="file"
                        accept="image/*"
                        className="block w-full mt-1"
                    />
                </div>
            </div>
            <div className="flex justify-end mt-5">
                <button type="submit" className="btn btn-outline" disabled={isSubmitting || isLoading}>
                    {isLoading ? 'Submitting....' : 'Submit'}
                </button>
            </div>
        </form>
    );
}

export default UserFormForBridge;
