import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import { Event } from '../../components/alert';
import { trpc } from '../../lib/trpc';

type CreateLiquidationPayload = {
    chain: string;
    currency: string;
    destination_wire_message?: string;
    destination_payment_rail?: string;
    destination_currency?: string;
    destination_address?: string;
};

const CreateLiquidityFormSchema = z.object({
    chain: z.string(),
    currency: z.string(),
    destination_wire_message: z.string().optional(),
    destination_payment_rail: z.string().optional(),
    destination_currency: z.string().optional(),
    destination_address: z.string().optional(),
});

export default function LiquidationForm({
    showAlert,
    closeModal,
    type,
    refetch
}: {
    showAlert: (message: string, type: Event, delay?: number) => void;
    closeModal: () => void;
    type: 'individual' | 'corporate';
    refetch: () => void;
}) {
    const params = useParams<{ id: string }>() as { id: string };

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        reset,
    } = useForm<CreateLiquidationPayload>({
        resolver: zodResolver(CreateLiquidityFormSchema),
        defaultValues: {
            chain: '',
            currency: '',
            destination_wire_message: '',
            destination_payment_rail: '',
            destination_address: '',
            destination_currency: '',
        },
    });

    const { mutate, isLoading } = trpc.admin.adminCreateLiquidationAddress.useMutation({
        onSuccess: res => {
            showAlert(res.message, 'success');
            refetch();
            closeModal();
            reset();
        },
        onError: err => {
            showAlert(err.message, 'error');
        },
    });

    const onSubmit: SubmitHandler<CreateLiquidationPayload> = data => {
        mutate({
            type: type,
            id: +params.id,
            ...data,
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-4">
                <div>
                    <label htmlFor="chain" className="block text-sm font-medium text-gray-700">
                        Chain
                    </label>
                    <select
                        id="chain"
                        {...register('chain', { required: 'Chain is required' })}
                        className={`border-[#0e2038] w-full border-[1px] mt-2 outline-none accent-[#0e2038] cursor-pointer p-3 rounded-lg`}
                    >
                        <option value="" disabled selected>
                            Select chain
                        </option>
                        <option value="arbitrum">Arbitrum</option>
                        <option value="avalanche_c_chain">Avalanche</option>
                        <option value="base">Base</option>
                        <option value="ethereum">Ethereum</option>
                        <option value="polygon">Polygon</option>
                        <option value="solana">Solana</option>
                        <option value="stellar">Stellar</option>
                    </select>
                    {errors.chain && <p className="mt-1 text-sm text-red-600">{errors.chain.message}</p>}
                </div>

                <div>
                    <label htmlFor="currency" className="block text-sm font-medium text-gray-700">
                        Currency
                    </label>
                    <select
                        id="currency"
                        {...register('currency')}
                        className={`border-[#0e2038] w-full border-[1px] mt-2 outline-none accent-[#0e2038] cursor-pointer p-3 rounded-lg`}
                    >
                        <option value="" disabled selected>
                            Select currency
                        </option>
                        <option value="usdc">USDC</option>
                        <option value="usdt">USDT</option>
                    </select>
                    {errors.currency && <p className="mt-1 text-sm text-red-600">{errors.currency.message}</p>}
                </div>

                <div>
                    <label htmlFor="destination_wire_message" className="block text-sm font-medium text-gray-700">
                        Destination Wire Message (optional)
                    </label>
                    <input
                        type="text"
                        id="destination_wire_message"
                        {...register('destination_wire_message')}
                        className={`border-[#0e2038] w-full border-[1px] mt-2 outline-none accent-[#0e2038] cursor-pointer p-3 rounded-lg`}
                    />
                </div>

                <div>
                    <label htmlFor="destination_payment_rail" className="block text-sm font-medium text-gray-700">
                        Destination Payment Rail (optional)
                    </label>
                    <select
                        id="destination_payment_rail"
                        {...register('destination_payment_rail')}
                        className={`border-[#0e2038] w-full border-[1px] mt-2 outline-none accent-[#0e2038] cursor-pointer p-3 rounded-lg`}
                    >
                        <option value="" disabled selected>
                            Select payment rail
                        </option>
                        <option value="wire">Wire</option>
                        <option value="ach">ACH</option>
                        <option value="sepa">SEPA</option>
                    </select>
                </div>

                <div>
                    <label htmlFor="destination_currency" className="block text-sm font-medium text-gray-700">
                        Destination Currency (optional)
                    </label>
                    <input
                        type="text"
                        id="destination_currency"
                        {...register('destination_currency')}
                        className={`border-[#0e2038] w-full border-[1px] mt-2 outline-none accent-[#0e2038] cursor-pointer p-3 rounded-lg`}
                    />
                </div>

                <div>
                    <label htmlFor="destination_address" className="block text-sm font-medium text-gray-700">
                        Destination Address (optional)
                    </label>
                    <input
                        type="text"
                        id="destination_address"
                        {...register('destination_address')}
                        className={`border-[#0e2038] w-full border-[1px] mt-2 outline-none accent-[#0e2038] cursor-pointer p-3 rounded-lg`}
                    />
                </div>
            </div>

            <button
                type="submit"
                disabled={isSubmitting || isLoading}
                className="w-full px-4 py-3 mt-10 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                {isSubmitting || isLoading ? 'Loading...' : 'Submit'}
            </button>
        </form>
    );
}
