import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Event } from '../../components/alert';
import Modal from '../../components/modal';
import useModal from '../../hooks/useModal';
import { trpc } from '../../lib/trpc';
import UserFormForBridge from './userFormForBridge';

const TermsOfService = ({
    type,
    showAlert,
    handleClear,
}: {
    type: 'individual' | 'corporate';
    showAlert: (message: string, type: Event, delay?: number) => void;
    handleClear: (value: string) => void;
}) => {
    const params = useParams<{ id: string }>() as { id: string };
    const { isModalVisible, setModalClose, setModalOpen } = useModal();

    const { mutate } = trpc.admin.adminCustomerPostToSBridgeUrl.useMutation({
        onSuccess: () => {
            setModalOpen(1);
        },
        onError: () => {
            console.log('error');
        },
    });

    const handlePostSignedAgreement = ({ signedAgreement }: { signedAgreement: string }) => {
        mutate({ signedAgreement, id: +params.id, type });
    };

    useEffect(() => {
        const handleMessage = (event: { origin: string; data: { signedAgreementId: string } }) => {
            if (event.origin === 'https://dashboard.bridge.xyz') {
                const { signedAgreementId } = event.data;

                if (signedAgreementId) {
                    handlePostSignedAgreement({
                        signedAgreement: signedAgreementId,
                    });
                }
            }
        };

        window.addEventListener('message', handleMessage);

        // Cleanup event listener when component unmounts
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return (
        <>
            <Modal
                title="Please Fill The Form To Continue"
                showModal={isModalVisible(1)}
                closeModal={() => {
                    setModalClose(1);
                    handleClear('');
                }}
                wrapperClassName="max-w-[500px]"
                containerClassName="pt-2"
            >
                <UserFormForBridge
                    showAlert={showAlert}
                    closeModal={() => {
                        setModalClose(1);
                        handleClear('');
                    }}
                />
            </Modal>
            <iframe
                id="tos-iframe"
                src="https://dashboard.bridge.xyz/accept-terms-of-service"
                style={{ width: '100%', height: '100%', border: 'none' }}
                title="Terms of Service"
            />
        </>
    );
};

export default TermsOfService;
