// Helper function to validate Base64 encoded image
export const validateBase64Image = (base64Image: string): boolean => {
    const base64ImageRegex = /^data:image\/(jpeg|jpg|png|heic|tif);base64,[A-Za-z0-9+/=]+$/;
    if (!base64ImageRegex.test(base64Image)) return false;

    // Extract Base64 data
    const base64Data = base64Image.split(',')[1];
    if (base64Data) {
        const sizeInBytes =
            base64Data.length * (3 / 4) - (base64Data.endsWith('==') ? 2 : base64Data.endsWith('=') ? 1 : 0);
        return sizeInBytes <= 10 * 1024 * 1024; // Check file size <= 10MB
    }
    return false;
};
