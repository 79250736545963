/* eslint-disable @typescript-eslint/no-explicit-any */
import { HTMLInputTypeAttribute, useState } from 'react';
import { useParams } from 'react-router-dom';
import Alert from '../../components/alert';
import LoaderContainer from '../../components/loader';
import Modal from '../../components/modal';
import Table from '../../components/table';
import useAlert from '../../hooks/useAlert';
import useModal from '../../hooks/useModal';
import { trpc } from '../../lib/trpc';
import BridgeCopyKycLink from '../copyBridgeKYCLink';
import ExternalAccountForm from '../externalAccountForm';
import LiquidationForm from '../liquidationAddressForm';
import VirtualAccountForm from '../virtualAccountForm';
import TermsOfService from './tosIFrame';
import UserFormForBridge from './userFormForBridge';

interface Option {
    value: string;
    label: string;
}
//
type Column = {
    label: string;
    key: string;
    format?: (v1: any, v2?: any) => any;
    meta?: { type: HTMLInputTypeAttribute | 'select'; options?: Option[] } | null;
};

const CautionSvg = ({ onMouseEnter, onMouseLeave }: { onMouseEnter: () => void; onMouseLeave: () => void }) => (
    <svg
        height="18px"
        width="18px"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 197.143 197.143"
        fill="#c61010"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
    >
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
            {' '}
            <g>
                {' '}
                <g>
                    {' '}
                    <path d="M195.031,166.074l-85.592-148.24c-2.226-3.89-6.403-6.306-10.89-6.306 c-4.477,0-8.65,2.412-10.894,6.292L1.68,166.747c-2.24,3.876-2.24,8.689,0,12.565c2.24,3.887,6.413,6.302,10.887,6.302h172.01 c6.929,0,12.565-5.644,12.565-12.58C197.143,170.447,196.377,167.956,195.031,166.074z M184.577,178.324H12.571 c-1.882,0-3.643-1.009-4.585-2.645c-0.945-1.636-0.948-3.665,0-5.3L93.961,21.456c0.941-1.628,2.698-2.645,4.588-2.645 c1.882,0,3.654,1.016,4.592,2.645l85.764,148.537c0.626,0.895,0.966,1.943,0.966,3.046 C189.871,175.952,187.491,178.324,184.577,178.324z"></path>{' '}
                    <polygon points="102.504,134.938 104.486,67.255 89.709,67.255 91.681,134.938 "></polygon>{' '}
                    <path d="M97.096,144.637c-5.146,0-8.879,3.905-8.879,9.28c0,5.39,3.733,9.294,8.879,9.294 c5.229,0,8.886-3.815,8.886-9.294C105.982,148.452,102.328,144.637,97.096,144.637z"></path>{' '}
                </g>{' '}
            </g>{' '}
        </g>
    </svg>
);

const externalAccountColumnHead: Column[] = [
    { label: 'Bank Name', key: 'bank_name' },
    { label: 'Account Name', key: 'account_owner_name' },
    { label: 'Main Beneficiary', key: 'mainBeneficiary' },
    { label: 'Active', key: 'active' },
    { label: 'Currency', key: 'currency' },
    { label: 'Last 4', key: 'account.last_4' },
    { label: 'Routing Number', key: 'account.routing_number' },
    { label: 'Account Type', key: 'account_type' },
];

const liquidationAddressColumnHead: Column[] = [
    { label: 'Chain', key: 'chain' },
    { label: 'Currency', key: 'currency' },
    { label: 'address', key: 'address' },
    { label: 'Payment Rail', key: 'destination_payment_rail' },
    { label: 'Destination Currency', key: 'destination_currency' },
];

const virtualAccountColumnHead: Column[] = [
    { label: 'Source Currency', key: 'source_deposit_instructions.currency' },
    { label: 'Bank Name', key: 'source_deposit_instructions.bank_name' },
    { label: 'Bank Account Number', key: 'source_deposit_instructions.bank_account_number' },
    { label: 'Bank Routing Number', key: 'source_deposit_instructions.bank_routing_number' },
    { label: 'Destination Currency', key: 'destination.currency' },
    { label: 'Destination Payment Rail', key: 'destination.payment_rail' },
    { label: 'Destination Address', key: 'destination.address' },
];

function UserBridgeSection() {
    const params = useParams<{ id: string }>() as { id: string };
    const [getTOS, setGetTOS] = useState('');
    const { alert, showAlert } = useAlert();
    const { isModalVisible, setModalClose, setModalOpen } = useModal();
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    const { data: userBridgeData, isLoading: userBridgeDataIsLoading } = trpc.admin.getUserBridgeData.useQuery({
        id: +params.id,
    });

    const { mutate: getToSMutation, isLoading: getToSMutationIsLoading } =
        trpc.admin.adminCustomerGetToSBridgeUrl.useMutation({
            onSuccess: res => {
                setGetTOS(res?.url as string);
            },
            onError: () => {
                showAlert('Failed to get TOS', 'error');
            },
        });

    const {
        data: checkSignedAgreement,
        isLoading: checkSignedAgreementIsLoading,
        refetch: refetchCheckSignedAgreement,
        isRefetching: checkSignedAgreementIsRefetching,
    } = trpc.admin.adminCheckSignedAgreement.useQuery(
        {
            type: 'individual',
            id: +params.id,
        },
        {
            refetchOnWindowFocus: false,
            enabled: userBridgeData?.bridgeData ? false : true,
        },
    );

    const { data: customerBridgeAccountDetail } = trpc.admin.adminGetCustomerBridgeAccount.useQuery({
        id: +params.id,
        type: 'individual',
    });

    const handleGetTOS = () => {
        getToSMutation({});
    };

    const {
        data: allCustomerExternalAccountsData,
        isLoading: allCustomerExternalAccountsDataLoading,
        refetch: refetchAllCustomerExternalAccounts,
    } = trpc.admin.adminGetCustomerAllExternalAccount.useQuery(
        {
            type: 'individual',
            id: +params.id,
        },
        {
            enabled: customerBridgeAccountDetail?.data ? true : false,
        },
    );

    const {
        data: allCustomerLiquidationAddressData,
        isLoading: allCustomerLiquidationAddressDataLoading,
        refetch: refetchAllCustomerLiquidationAddress,
    } = trpc.admin.GetCustomerAllLiquidationAddress.useQuery(
        {
            type: 'individual',
            id: +params.id,
        },
        {
            enabled: customerBridgeAccountDetail?.data ? true : false,
        },
    );

    const {
        data: allCustomerVirtualAccountsData,
        isLoading: allCustomerVirtualAccountsDataLoading,
        refetch: refetchAllCustomerVirtualAccounts,
    } = trpc.admin.adminGetCustomerAllVirtualAccount.useQuery(
        {
            type: 'individual',
            id: +params.id,
        },
        {
            enabled: customerBridgeAccountDetail?.data ? true : false,
        },
    );

    return (
        <div>
            {alert && <Alert classes={'w-[20%] ml-auto'} message={alert.message} event={alert.type} />}
            <Modal
                title="Please Fill The Form To Continue"
                showModal={isModalVisible(1)}
                closeModal={() => setModalClose(1)}
                wrapperClassName="max-w-[500px]"
                containerClassName="pt-2"
            >
                <UserFormForBridge showAlert={showAlert} closeModal={() => setModalClose(1)} />
            </Modal>
            <Modal
                title="Add External Account"
                showModal={isModalVisible(2)}
                closeModal={() => setModalClose(2)}
                wrapperClassName="max-w-[500px]"
                containerClassName="pt-2"
            >
                <ExternalAccountForm
                    closeModal={() => setModalClose(2)}
                    showAlert={showAlert}
                    type="individual"
                    refetch={refetchAllCustomerExternalAccounts}
                />
            </Modal>
            <Modal
                title="Add Liquidation Address"
                showModal={isModalVisible(3)}
                closeModal={() => setModalClose(3)}
                wrapperClassName="max-w-[500px]"
                containerClassName="pt-2"
            >
                <LiquidationForm
                    closeModal={() => setModalClose(3)}
                    showAlert={showAlert}
                    type="individual"
                    refetch={refetchAllCustomerLiquidationAddress}
                />
            </Modal>
            <Modal
                title="Add Virtual Account"
                showModal={isModalVisible(4)}
                closeModal={() => setModalClose(4)}
                wrapperClassName="max-w-[500px]"
                containerClassName="pt-2"
            >
                <VirtualAccountForm
                    closeModal={() => setModalClose(4)}
                    showAlert={showAlert}
                    type="individual"
                    refetch={refetchAllCustomerVirtualAccounts}
                />
            </Modal>
            <LoaderContainer loading={checkSignedAgreementIsRefetching || checkSignedAgreementIsLoading}>
                {customerBridgeAccountDetail !== undefined && customerBridgeAccountDetail?.data ? (
                    <>
                        <LoaderContainer loading={userBridgeDataIsLoading}>
                            <div tabIndex={0} className="collapse bg-base-200">
                                <div className="mb-2 text-xl font-bold cursor-pointer">
                                    Bridge User Data <span className="text-xs text-red-600">(Click to View)</span>
                                </div>
                                <div className="p-4 bg-white border border-gray-200 rounded-lg collapse-content">
                                    <pre className="p-4 font-mono text-sm text-gray-800 bg-gray-100 rounded-lg">
                                        {JSON.stringify(customerBridgeAccountDetail?.data, null, 2)}
                                    </pre>
                                </div>
                            </div>
                        </LoaderContainer>

                        <div className="mt-5">
                            <div className="relative flex items-center justify-between mb-2">
                                <h1 className="text-xl font-bold cursor-pointer ">KYC Link</h1>
                                <div className="flex items-center text-sm gap-x-2">
                                    <h1 className="text-xl font-bold cursor-pointer">Current KYC Status: </h1>{' '}
                                    <div className="flex items-center gap-x-1">
                                        <p className="text-xl uppercase">
                                            {customerBridgeAccountDetail?.data?.status?.replaceAll('_', ' ')}
                                        </p>{' '}
                                        {['rejected', 'under review'].includes(
                                            customerBridgeAccountDetail?.data?.status?.replaceAll('_', ' '),
                                        ) && (
                                            <CautionSvg
                                                onMouseEnter={() => setIsTooltipVisible(true)}
                                                onMouseLeave={() => setIsTooltipVisible(false)}
                                            />
                                        )}
                                    </div>
                                </div>
                                {isTooltipVisible && (
                                    <span
                                        className="absolute right-0 px-3 py-1 mb-2 text-sm text-white bg-gray-800 rounded-md bottom-full whitespace-nowrap"
                                        role="tooltip"
                                    >
                                        {customerBridgeAccountDetail?.data?.rejection_reasons?.map(reason => {
                                            return (
                                                <ul key={reason.developer_reason} className="px-4 py-1 list-disc">
                                                    <li>{reason.developer_reason}</li>
                                                </ul>
                                            );
                                        })}
                                    </span>
                                )}
                            </div>
                            <BridgeCopyKycLink
                                type="individual"
                                isFetched={customerBridgeAccountDetail !== undefined ? true : false}
                            />
                        </div>

                        <div className="mt-5">
                            <LoaderContainer loading={allCustomerExternalAccountsDataLoading}>
                                <div className="flex justify-end mt-9">
                                    <button onClick={() => setModalOpen(2)} className="btn">
                                        Add External Account
                                    </button>
                                </div>
                                <div className="flex mt-1 mb-5">
                                    <Table
                                        title="External Accounts"
                                        subtitle="List of all external accounts associated with this user"
                                        columns={externalAccountColumnHead}
                                        data={allCustomerExternalAccountsData?.externalAccounts?.data || []}
                                    />
                                </div>
                            </LoaderContainer>
                        </div>

                        <div className="mt-5">
                            <LoaderContainer loading={allCustomerLiquidationAddressDataLoading}>
                                <div className="flex justify-end mt-9">
                                    <button onClick={() => setModalOpen(3)} className="btn">
                                        Add Liquidation Address
                                    </button>
                                </div>
                                <div className="flex mt-1 mb-5">
                                    <Table
                                        title="Liquidation Addresses"
                                        subtitle="List of all liquidation addresses associated with this user"
                                        columns={liquidationAddressColumnHead}
                                        data={[allCustomerLiquidationAddressData?.liquidation?.data?.items || []]}
                                    />
                                </div>
                            </LoaderContainer>
                        </div>

                        <div className="mt-5">
                            <LoaderContainer loading={allCustomerVirtualAccountsDataLoading}>
                                <div className="flex justify-end mt-9">
                                    <button onClick={() => setModalOpen(4)} className="btn">
                                        Add Virtual Account
                                    </button>
                                </div>
                                <div className="flex mt-1 mb-5">
                                    <Table
                                        title="Liquidation Addresses"
                                        subtitle="List of all virtual accounts associated with this user"
                                        columns={virtualAccountColumnHead}
                                        data={[allCustomerVirtualAccountsData?.virtualAccounts?.data || []]}
                                    />
                                </div>
                            </LoaderContainer>
                        </div>
                    </>
                ) : (
                    <>
                        {getTOS !== '' ? (
                            <TermsOfService
                                type={'individual'}
                                showAlert={showAlert}
                                handleClear={value => {
                                    setGetTOS(value);
                                    refetchCheckSignedAgreement();
                                }}
                            />
                        ) : (
                            <div className="flex flex-col items-center justify-center w-full h-full p-8 mt-10">
                                <p>
                                    {checkSignedAgreement?.success
                                        ? 'Continue to create user bridge account for this user'
                                        : 'To create user bridge account for this user, click the button below'}
                                </p>
                                {checkSignedAgreement?.success ? (
                                    <button
                                        className="text-white bg-[#374151] px-3 py-2 border-2 border-solid rounded-lg border-[#374151] font-medium mt-5"
                                        onClick={() => setModalOpen(1)}
                                    >
                                        Continue
                                    </button>
                                ) : (
                                    <button
                                        className="text-white bg-[#374151] px-3 py-2 border-2 border-solid rounded-lg border-[#374151] font-medium mt-5"
                                        onClick={handleGetTOS}
                                    >
                                        {getToSMutationIsLoading
                                            ? 'Creating Bridge Account...'
                                            : 'Create Bridge Account'}
                                    </button>
                                )}
                            </div>
                        )}
                    </>
                )}
            </LoaderContainer>
        </div>
    );
}

export default UserBridgeSection;
