import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import { Event } from '../../components/alert';
import { trpc } from '../../lib/trpc';

type CreateVirtualAccountPayloadType = {
    source: {
        currency: string;
    };
    destination: {
        currency: string;
        address: string;
        payment_rail: string;
    };
};

const createVirtualAccountSchema = z.object({
    source: z.object({
        currency: z.string(),
    }),
    destination: z.object({
        currency: z.string(),
        address: z.string(),
        payment_rail: z.string(),
    }),
});

export default function VirtualAccountForm({
    showAlert,
    closeModal,
    type,
    refetch
}: {
    showAlert: (message: string, type: Event, delay?: number) => void;
    closeModal: () => void;
    type: 'individual' | 'corporate';
    refetch: () => void;
}) {
    const params = useParams<{ id: string }>() as { id: string };

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        reset,
    } = useForm<CreateVirtualAccountPayloadType>({
        resolver: zodResolver(createVirtualAccountSchema),
    });

    const { mutate, isLoading } = trpc.admin.adminCreateCustomerVirtualAccount.useMutation({
        onSuccess: res => {
            showAlert(res.message, 'success');
            refetch();
            closeModal();
            reset();
        },
        onError: err => {
            showAlert(err.message, 'error');
        },
    });

    const onSubmit: SubmitHandler<CreateVirtualAccountPayloadType> = data => {
        console.log(data);
        // Here you would typically send this data to your API
        mutate({
            type: type,
            id: +params.id,
            source: data.source,
            destination: data.destination,
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div>
                <label htmlFor="sourceCurrency" className="block text-sm font-medium text-gray-700">
                    Source Currency
                </label>
                <input
                    {...register('source.currency', { required: 'Source currency is required' })}
                    id="sourceCurrency"
                    type="text"
                    className={`border-[#0e2038] w-full border-[1px] mt-2 outline-none accent-[#0e2038] cursor-pointer p-3 rounded-lg`}
                />
                {errors.source?.currency && (
                    <p className="mt-1 text-sm text-red-600">{errors.source.currency.message}</p>
                )}
            </div>

            <div>
                <label htmlFor="destinationCurrency" className="block text-sm font-medium text-gray-700">
                    Destination Currency
                </label>
                <input
                    {...register('destination.currency', { required: 'Destination currency is required' })}
                    id="destinationCurrency"
                    type="text"
                    className={`border-[#0e2038] w-full border-[1px] mt-2 outline-none accent-[#0e2038] cursor-pointer p-3 rounded-lg`}
                />
                {errors.destination?.currency && (
                    <p className="mt-1 text-sm text-red-600">{errors.destination.currency.message}</p>
                )}
            </div>

            <div>
                <label htmlFor="destinationAddress" className="block text-sm font-medium text-gray-700">
                    Destination Address
                </label>
                <input
                    {...register('destination.address', { required: 'Destination address is required' })}
                    id="destinationAddress"
                    type="text"
                    className={`border-[#0e2038] w-full border-[1px] mt-2 outline-none accent-[#0e2038] cursor-pointer p-3 rounded-lg`}
                />
                {errors.destination?.address && (
                    <p className="mt-1 text-sm text-red-600">{errors.destination.address.message}</p>
                )}
            </div>

            <div>
                <label htmlFor="paymentRail" className="block text-sm font-medium text-gray-700">
                    Payment Rail
                </label>
                <input
                    {...register('destination.payment_rail', { required: 'Payment rail is required' })}
                    id="paymentRail"
                    type="text"
                    className={`border-[#0e2038] w-full border-[1px] mt-2 outline-none accent-[#0e2038] cursor-pointer p-3 rounded-lg`}
                />
                {errors.destination?.payment_rail && (
                    <p className="mt-1 text-sm text-red-600">{errors.destination.payment_rail.message}</p>
                )}
            </div>

            <button
                type="submit"
                disabled={isLoading || isSubmitting}
                className="w-full px-4 py-3 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                {isSubmitting ? 'Submitting...' : 'Create Virtual Account'}
            </button>
        </form>
    );
}
