import { twMerge } from 'tailwind-merge';

interface ModalProps {
    children: JSX.Element;
    showModal: boolean;
    closeModal: () => void;
    title: string;
    containerClassName?: string;
    useTitle?: boolean;
    useCloseButton?: boolean;
    useTitleClassName?: string;
    useChildButton?: boolean;
    wrapperClassName?: string;
}

const Modal = ({
    children,
    closeModal,
    showModal,
    title,
    useTitleClassName,
    useTitle = true,
    containerClassName,
    useCloseButton = true,
    wrapperClassName
}: ModalProps) => {
    const renderCloseButton = () => {
        return (
            <button
                onClick={closeModal}
                type="button"
                className="inline-flex items-center justify-center w-8 h-8 text-sm text-gray-400 bg-transparent rounded-lg hover:bg-gray-200 hover:text-gray-900 ms-auto"
                data-modal-hide="default-modal"
            >
                <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                >
                    <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                </svg>
                <span className="sr-only">Close modal</span>
            </button>
        );
    };
    return (
        <>
            {showModal ? (
                <>
                    <div className="fixed inset-0 z-50 bg-black opacity-70" onClick={closeModal}></div>
                    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                        <div className={twMerge('relative p-4 w-full max-w-2xl max-h-full', wrapperClassName)}>
                            <div className="relative bg-white rounded-lg shadow">
                                <div
                                    className={twMerge(
                                        'flex items-center justify-between p-4 md:p-5 border-b rounded-t',
                                        useTitleClassName,
                                    )}
                                >
                                    {useTitle && <h3 className="text-xl font-semibold text-gray-900">{title}</h3>}
                                    {useCloseButton && renderCloseButton()}
                                </div>
                                <div className={twMerge('p-4 md:p-5 space-y-4', containerClassName)}>{children}</div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
};

export default Modal;
