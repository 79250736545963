import { zodResolver } from '@hookform/resolvers/zod';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import Alert from '../../components/alert';
import Modal from '../../components/modal';
import useAlert from '../../hooks/useAlert';
import useModal from '../../hooks/useModal';
import { trpc } from '../../lib/trpc';

interface DropdownProps {
    options: string[];
    selected: string;
    setSelected: (value: string) => void;
    label: string;
    isFiat: boolean;
    isSwapped: boolean;
    refContainer: React.RefObject<HTMLDivElement>;
    showDropdown: boolean;
    toggleDropdown: () => void;
}

const Dropdown: React.FC<DropdownProps> = ({
    options,
    selected,
    setSelected,
    label,
    isFiat,
    refContainer,
    showDropdown,
    toggleDropdown,
}) => {
    return (
        <div>
            <p className="text-right text-[11px] font-normal text-htext-subdued">{label}</p>
            <div
                className="mt-2.5 cursor-pointer rounded-[500px] border-0 bg-gray-400 py-1 pl-2 pr-2.5 text-right text-[13px] font-medium outline-none hover:bg-gray-300"
                onClick={toggleDropdown}
                ref={refContainer}
            >
                <div className="flex items-center">
                    <img
                        className="w-[14px]"
                        src={`/assets/svg/${
                            isFiat
                                ? selected === 'EUR'
                                    ? 'euroSmall'
                                    : selected === 'GBP'
                                      ? 'poundsSmall'
                                      : 'dollarSmall'
                                : selected === 'USDC'
                                  ? 'usdcSmall'
                                  : 'usdtSmall'
                        }.svg`}
                        alt={selected}
                    />
                    <p className="ml-1 mr-2.5">{selected}</p>
                    <img src="/assets/svg/BlueThinArrow.svg" alt="-" />
                </div>
                {showDropdown && (
                    <ul className="absolute z-30 mt-2 w-[85px] rounded-lg border border-solid border-gray-300 bg-gray-100 overflow-hidden">
                        {options.map(option => (
                            <li
                                key={option}
                                className="cursor-pointer px-4 py-1 text-left text-[13px] hover:bg-gray-300"
                                onClick={() => {
                                    setSelected(option);
                                    toggleDropdown();
                                }}
                            >
                                <p>{option}</p>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

const validationSchema = z
    .object({
        email: z.string().min(1, 'Email is required').email('Invalid email address'),
    })
    .required();

function RateCalculator() {
    const stableDropdownRef = useRef<HTMLDivElement>(null);
    const fiatDropdownRef = useRef<HTMLDivElement>(null);
    const { isModalVisible, setModalClose, setModalOpen } = useModal();
    const { alert, showAlert } = useAlert();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            email: '',
        },
        resolver: zodResolver(validationSchema),
    });
    const stableCoinOptions = ['USDT', 'USDC'];
    const fiatOptions = ['EUR', 'GBP'];

    const [state, setState] = useState({
        amount: '',
        currencyBase: stableCoinOptions[0],
        currencyTarget: fiatOptions[0],
        showDropdown: { stable: false, fiat: false },
        isSwapped: false,
    });

    const fromOptions = state.isSwapped ? fiatOptions : stableCoinOptions;
    const toOptions = state.isSwapped ? stableCoinOptions : fiatOptions;

    const handleSwap = useCallback(() => {
        setState(prevState => ({
            ...prevState,
            isSwapped: !prevState.isSwapped,
            currencyBase: prevState.currencyTarget,
            currencyTarget: prevState.currencyBase,
        }));
    }, [state.currencyBase, state.currencyTarget]);

    const isStableCoin = (str: string) => stableCoinOptions.includes(str);

    const { data: tr } = trpc.adminInfo.getCalculatedRate.useQuery(
        {
            base: isStableCoin(state.currencyBase) ? 'USD' : state.currencyBase,
            target: isStableCoin(state.currencyTarget) ? 'USD' : state.currencyTarget,
        },
        { enabled: !!state.amount },
    );

    const calculatedValue = useCallback(() => {
        if (!state.amount || isNaN(parseFloat(state.amount))) return '0';
        if (!tr) return '0';

        const rate = tr.exchange_rate;
        return (parseFloat(state.amount) * rate).toFixed(2);
    }, [state.amount, tr]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (/^\d*\.?\d*$/.test(value)) {
            setState(prevState => ({ ...prevState, amount: value }));
        }
    };

    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (stableDropdownRef.current && !stableDropdownRef.current.contains(event.target as Node)) {
            setState(prevState => ({ ...prevState, showDropdown: { ...prevState.showDropdown, stable: false } }));
        }
        if (fiatDropdownRef.current && !fiatDropdownRef.current.contains(event.target as Node)) {
            setState(prevState => ({ ...prevState, showDropdown: { ...prevState.showDropdown, fiat: false } }));
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    const { mutate, isLoading } = trpc.adminInfo.sharePaymentLink.useMutation({
        onSuccess: () => {
            showAlert && showAlert('Rate shared successfully', 'success');
            reset();
            setModalClose(1);
        },
        onError: err => {
            showAlert && showAlert(err.message, 'error');
        },
    });

    const submitForm = (data: { email: string }) => {
        const calValue = calculatedValue();
        const exchangeRate = tr?.exchange_rate;
        if (!exchangeRate || isNaN(Number(exchangeRate))) {
            showAlert && showAlert('Invalid exchange rate', 'error');
            return; // Exit early if the exchange rate is invalid
        }

        if (!state.amount || isNaN(Number(state.amount))) {
            showAlert && showAlert('Invalid amount to send', 'error');
            return; // Exit early if the amount to send is invalid
        }

        if (!calValue || isNaN(Number(calValue))) {
            showAlert && showAlert('Invalid amount to receive', 'error');
            return; // Exit early if the amount to receive is invalid
        }

        mutate({
            email: data.email,
            rate: Number(tr?.exchange_rate),
            currencyPair: `${state.currencyBase} - ${state.currencyTarget}`,
            amountToSend: Number(state.amount),
            amountToReceive: Number(calValue),
        });
    };

    return (
        <div className="w-full h-full">
            {alert && <Alert classes={'w-[20%] ml-auto'} message={alert.message} event={alert.type} />}
            <Modal title="Share Rate" showModal={isModalVisible(1)} closeModal={() => setModalClose(1)}>
                <div>
                    <p> You are about to share a payment request. Please enter the email address of the recipient. </p>

                    <form>
                        <div>
                            <input
                                type="email"
                                placeholder="Email"
                                {...register('email')}
                                className="w-full px-3 py-2 mt-1 border border-gray-700 rounded-md outline-none cursor-pointer"
                            />
                            {errors.email && <p className="mt-1 text-xs text-red-500">{errors.email.message}</p>}
                        </div>

                        <div className="mt-3.5 flex justify-center">
                            <button
                                className="mt-3.5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                type="submit"
                                onClick={handleSubmit(submitForm)}
                            >
                                {isLoading ? 'Sharing...' : 'Share Rate'}
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
            <div
                className={`quick-action w-full rounded-lg border border-solid border-gray-200 bg-white px-5 ${
                    calculatedValue() !== '0' && state.amount ? 'pb-2.5 pt-5' : 'pb-6 pt-6'
                } text-htext-main`}
            >
                <div className="flex items-center justify-between w-full">
                    <h5 className="text-[16.5px] font-semibold">Rate calculator</h5>
                    <div
                        onClick={() => {
                            if (!state.amount || !calculatedValue) return showAlert('Amount is required', 'error');
                            setModalOpen(1);
                        }}
                        className="flex items-center justify-center mt-2 text-blue-700 cursor-pointer w-fit hover:border-b hover:border-blue-600 hover:text-blue-600"
                    >
                        <img src="/assets/svg/share.svg" alt="share icon" />
                        <p className="ml-2.5 text-[14px] font-semibold">Share payment request</p>
                    </div>
                </div>

                <div className={`actions-content relative mt-6 flex h-full w-full flex-col items-center gap-y-2`}>
                    <div className="flex w-full items-center justify-between rounded-[9px] bg-gray-50 px-4 py-3.5">
                        <div className="w-[160px]">
                            <p className="text-left text-[13px] font-medium text-htext-main">From</p>
                            <input
                                type="text"
                                className="mt-2.5 bg-transparent text-left text-[18px] font-normal text-htext-placeholder outline-none"
                                placeholder={
                                    state.isSwapped
                                        ? (state.currencyBase === 'EUR'
                                              ? '€'
                                              : state.currencyBase === 'GBP'
                                                ? '£'
                                                : '$') + '0'
                                        : '0'
                                }
                                value={state.amount}
                                onChange={handleInputChange}
                            />
                        </div>
                        <Dropdown
                            options={fromOptions}
                            selected={state.currencyBase}
                            setSelected={value => setState(prevState => ({ ...prevState, currencyBase: value }))}
                            label={state.isSwapped ? 'Fiat' : 'Stablecoin'}
                            isFiat={state.isSwapped}
                            isSwapped={state.isSwapped}
                            refContainer={stableDropdownRef}
                            showDropdown={state.showDropdown.stable}
                            toggleDropdown={() =>
                                setState(prevState => ({
                                    ...prevState,
                                    showDropdown: { ...prevState.showDropdown, stable: !prevState.showDropdown.stable },
                                }))
                            }
                        />
                    </div>

                    <div
                        className="swap-div absolute mt-[4.45rem] cursor-pointer hover:rounded-full hover:border"
                        onClick={handleSwap}
                    >
                        <img src="/assets/svg/rates-sort.svg" alt="swap icon" />
                    </div>

                    <div className="flex w-full items-center justify-between rounded-[9px] bg-gray-50 px-4 py-3.5">
                        <div className="w-[160px]">
                            <p className="text-left text-[13px] font-medium text-htext-main">To</p>
                            <input
                                type="text"
                                className="mt-2.5 bg-transparent text-left text-[18px] font-normal text-htext-placeholder outline-none"
                                placeholder={
                                    state.currencyTarget === 'EUR'
                                        ? '€'
                                        : state.currencyTarget === 'GBP'
                                          ? '£'
                                          : state.currencyTarget === 'USD'
                                            ? '$'
                                            : '0'
                                }
                                readOnly
                                value={calculatedValue()}
                            />
                        </div>
                        <Dropdown
                            options={toOptions}
                            selected={state.currencyTarget}
                            setSelected={value => setState(prevState => ({ ...prevState, currencyTarget: value }))}
                            label={state.isSwapped ? 'Stablecoin' : 'Fiat'}
                            isFiat={!state.isSwapped}
                            isSwapped={state.isSwapped}
                            refContainer={fiatDropdownRef}
                            showDropdown={state.showDropdown.fiat}
                            toggleDropdown={() =>
                                setState(prevState => ({
                                    ...prevState,
                                    showDropdown: { ...prevState.showDropdown, fiat: !prevState.showDropdown.fiat },
                                }))
                            }
                        />
                    </div>
                </div>

                {calculatedValue() !== '0' && state.amount && (
                    <p className="mt-1 text-right text-[13px] font-semibold">Rate: {tr?.exchange_rate}</p>
                )}
            </div>
        </div>
    );
}

export default RateCalculator;
