import { useParams } from 'react-router-dom';
import CopyToClipboardInput from '../../components/clipboard';
import LoaderContainer from '../../components/loader';
import { trpc } from '../../lib/trpc';

function BridgeCopyKycLink({ type, isFetched }: { type: 'individual' | 'corporate'; isFetched: boolean }) {
    const params = useParams<{ id: string }>() as { id: string };

    const { data, isLoading } = trpc.admin.adminGetCustomerKYCData.useQuery(
        {
            id: +params.id,
            type: type,
        },
        {
            enabled: isFetched,
        },
    );

    return (
        <LoaderContainer loading={isLoading}>
            <CopyToClipboardInput url={data?.kycData as string} />
        </LoaderContainer>
    );
}

export default BridgeCopyKycLink;
